import { HandySvg } from 'handy-svg'
import Modal, { useModalState } from 'react-simple-modal-provider'
import './style.scss'
import IconClose from 'src/icons/close.svg'
import FColModalUpgradeForm from './form'
import { useTranslation } from 'react-i18next'

export default function FColModalUpgrade({ children }) {
	const [isOpen, setOpen] = useModalState()
	const closeModal = () => {
		setOpen(false)
	}

	const { t } = useTranslation()

	return (
		<Modal
			id={'FColModalUpgrade'}
			consumer={children}
			isOpen={isOpen}
			setOpen={setOpen}
			draggable={false}
			duration={300}
		>
			<div className="modal FColModalUpgrade">
				<div className="modal_header">
					<div className="modal_title">{t('fcol_nft_title')}</div>
					<button className="modal_close" onClick={closeModal}>
						<HandySvg src={IconClose} />
					</button>
				</div>
				<div className="modal_body">
					<FColModalUpgradeForm />
				</div>
			</div>
		</Modal>
	)
}
