import React from 'react'
import Page from '../components/Page'
import ErrorComponent from '../components/ErrorComponent'

export default function NotFoundPage() {
	return (
		<Page title={'Page not found'}>
			<ErrorComponent />
		</Page>
	)
}
