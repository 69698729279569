import './Layout.scss'
import Sidebar from './Sidebar'

export default function Layout(props) {
	return (
		<div className="Layout">
			<div className="container">
				<Sidebar />
				<div className="Layout-content">{props.children}</div>
			</div>
		</div>
	)
}
