import React, { useState, useEffect } from 'react'
import { useModalProps } from 'react-simple-modal-provider'
import { useTranslation } from 'react-i18next'

type dataType = {
	id: string
	series: string
	price: string
	date: string
}

const values = [
	{ id: '0', series: '3', price: '3', date: new Date().toDateString() },
	{ id: '1', series: '1', price: '3', date: new Date().toLocaleString() },
	{ id: '2', series: '2', price: '3', date: new Date().toLocaleString() },
	{ id: '3', series: '12', price: '3', date: new Date().toLocaleString() },
	{ id: '4', series: '3', price: '3', date: new Date().toLocaleString() },
	{ id: '5', series: '3', price: '3', date: new Date().toLocaleString() },
	{ id: '6', series: '1', price: '3', date: new Date().toLocaleString() },
	{ id: '7', series: '1', price: '3', date: new Date().toLocaleString() },
	{ id: '8', series: '2', price: '3', date: new Date().toLocaleString() },
]

export default function ModalNodeForm() {
	const { address } = useModalProps('FColModalNode')
	const [data, setData] = useState<dataType[]>([])
	const { t } = useTranslation()

	useEffect(() => {
		setData(values)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [address])
	return (
		<form className="ModalNode-form">
			<div className="ModalNode-form_row">
				<div className="ModalNode-form_header" style={{ width: '20%' }}>
					{t('fcol_line_structure_table_no')}
				</div>
				<div className="ModalNode-form_header" style={{ width: '20%' }}>
					{t('fcol_line_structure_table_series')}
				</div>
				<div className="ModalNode-form_header" style={{ width: '30%' }}>
					{t('fcol_line_structure_table_price')}
				</div>
				<div className="ModalNode-form_header" style={{ width: '30%' }}>
					{t('fcol_line_structure_table_date')}
				</div>
			</div>
			<div className="ModalNode-form_body">
				{data.map((item: dataType, index) => (
					<div
						className="ModalNode-form_row"
						style={{ background: index % 2 ? '#172028' : '#1b2936' }}
					>
						<div className="ModalNode-form_cell" style={{ width: '20%' }}>
							{index + 1}
						</div>
						<div className="ModalNode-form_cell" style={{ width: '20%' }}>
							{item.series}
						</div>
						<div className="ModalNode-form_cell" style={{ width: '30%' }}>
							{item.price}
						</div>
						<div className="ModalNode-form_cell" style={{ width: '30%' }}>
							{item.date}
						</div>
					</div>
				))}
			</div>
		</form>
	)
}
