import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './LangSwitcher.scss'
import Cookies from 'js-cookie'

function LangSwitcher() {
	const [language, setLanguage] = useState(Cookies.get('locale') || 'en')
	const { i18n } = useTranslation()

	const changeLanguage = (lang) => {
		if (i18n.language !== lang) {
			i18n.changeLanguage(lang)
			Cookies.set('locale', lang)
			setLanguage(lang)
		}
	}

	return (
		<div className="lang-switcher">
			{language !== 'en' ? (
				<button onClick={() => changeLanguage('en')}>En</button>
			) : (
				<button onClick={() => changeLanguage('ru')}>Ru</button>
			)}
		</div>
	)
}

export default LangSwitcher
