import React, { useState, useEffect } from 'react'
import { Address, useContractRead } from 'wagmi'
import FColLinearStructureNode from './node'
import { itemConfig } from './canvas'
import { FCOL_ADDRESS } from 'src/constants'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'

type childState = {
	index: number
	address: Address
	childNum: number
}

interface Props {
	lvl: number
	index: number
	address: Address
	canvasWidth: number
	parentX: number
	parentDX: number
	parentNumberArray: Record<string, number>
	setChildCount: (id: number, n: number, lastLvl: number) => void
}

export default function FColLinearStructureItem(props: Props) {
	const [child, setChild] = useState<childState[]>([])
	const [childNum, setChildNum] = useState<number>(0)
	const [bgColor, setBgColor] = useState<string>('transparent')
	const [parentColor, setParentColor] = useState<string>('transparent')
	const [lastLvl, setLastLvl] = useState<number>(0)
	const [childNumberArray, setChildNumberArray] = useState<
		Record<string, number>
	>({})
	const [x, setX] = useState<number>(0)
	const [dx, setDx] = useState<number>(0)
	const limitLevel = 8
	const lastLvlArray = {} as Record<string, number>

	let lvl = props.lvl + 1
	const width = itemConfig.width
	const height = itemConfig.height
	const margin = itemConfig.margin
	const w = width + margin
	const h = height + margin
	const y = props.lvl * h + itemConfig.canvasMargin

	useEffect(() => {
		let beforeNodeNum = 0
		const parentNumberArray = props.parentNumberArray
		// eslint-disable-next-line array-callback-return
		Object.keys(parentNumberArray).map((index) => {
			if (Number(index) < props.index) beforeNodeNum += parentNumberArray[index]
		})
		setX(props.parentX + w * beforeNodeNum)
		setDx((w * childNum) / 2 - w / 2)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.parentX, props.parentNumberArray, childNum])

	const { data: linearNode } = useContractRead({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'getLinearNode',
		args: [props.address],
		select: (data: any) => {
			return {
				registeredAt: data[0],
				lastClaimedAt: data[1],
				referrer: data[2] as Address,
				referees: data[3] as Array<Address>,
			}
		},
	})

	const getBgColor = (lvl: number) => {
		let color = ''
		switch (lvl) {
			case 1:
				color = '#5C0148'
				break
			case 2:
				color = '#821592'
				break
			case 3:
				color = '#791B7D'
				break
			case 4:
				color = '#490776'
				break
			case 5:
				color = '#52229C'
				break
			case 6:
				color = '#7247B5'
				break
			case 7:
				color = '#090A61'
				break
			case 8:
				color = '#27097C'
				break
			default:
				color = '#5C0148'
		}
		return color
	}

	useEffect(() => {
		setBgColor(getBgColor(lvl))
		setParentColor(getBgColor(lvl - 1))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lvl])

	useEffect(() => {
		let temp: Array<childState> = []
		if (linearNode && lvl <= limitLevel) {
			linearNode.referees.map((referee, index) =>
				temp.push({
					index,
					address: referee,
					childNum: 0,
				})
			)
			setChild(temp)
		}

		if (!Boolean(linearNode?.referees.length)) {
			setChildNum(1)
			setLastLvl(lvl)
		}
	}, [linearNode, lvl])

	useEffect(() => {
		props.setChildCount(props.index, childNum, lastLvl)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childNum, lastLvl])

	const handlerChildCount = (id: number, n: number, lastLvl: number) => {
		childNumberArray[id] = n
		lastLvlArray[id] = lastLvl
		let num = 0
		// eslint-disable-next-line array-callback-return
		Object.keys(childNumberArray).map((index) => {
			num += childNumberArray[index]
		})
		setChildNum(num)
		setChildNumberArray(childNumberArray)

		let lvl = 0
		// eslint-disable-next-line array-callback-return
		Object.keys(lastLvlArray).map((index) => {
			if (lastLvlArray[index] > lvl) lvl = lastLvlArray[index]
		})
		setLastLvl(lvl)
	}

	return (
		<g>
			{childNum > 0 ? (
				<g>
					<FColLinearStructureNode
						bgColor={bgColor}
						address={props.address}
						x={x + dx}
						y={y}
						width={width}
						height={height}
					/>
					{lvl > 1 ? (
						<g>
							<line
								x1={props.parentX + props.parentDX + width / 2}
								y1={y - h / 4}
								x2={x + dx + width / 2}
								y2={y - h / 4}
								style={{
									strokeWidth: '3',
									stroke: parentColor,
								}}
							/>
							<line
								x1={x + dx + width / 2}
								y1={y - h / 4}
								x2={x + dx + width / 2}
								y2={y}
								style={{
									strokeWidth: '3',
									stroke: parentColor,
								}}
							/>
						</g>
					) : (
						<></>
					)}
				</g>
			) : (
				<></>
			)}
			{child.map((item, index) => (
				<FColLinearStructureItem
					key={index}
					lvl={lvl}
					index={item.index}
					address={item.address}
					canvasWidth={props.canvasWidth}
					parentX={x}
					parentDX={dx}
					parentNumberArray={childNumberArray}
					setChildCount={handlerChildCount}
				/>
			))}
		</g>
	)
}
