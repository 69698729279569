import React from 'react'
import { useModal } from 'react-simple-modal-provider'

import { BigNumber, ethers } from 'ethers'
import { Address, useContractRead, useEnsAvatar, useEnsName } from 'wagmi'

import { useContract } from 'src/hooks/useContract'
import { FCOL_ADDRESS } from 'src/constants'
import GrabCoinClubAbi from 'src/abis/GrabCoinClubAbi.json'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'
import getShortWallet from 'src/utils/getShortWallet'

interface Props {
	bgColor: string
	address: Address
	x: number
	y: number
	width: number
	height: number
}

export default function FColLinearStructureNode(props: Props) {
	const x = props.x
	const y = props.y
	const width = props.width
	const height = props.height

	const { nftCollection, stakedTokenId } = useContract()
	const { open: openFColModalNode } = useModal('FColModalNode')

	const { data: ensAvatar } = useEnsAvatar({ address: props.address })
	const { data: ensName } = useEnsName({ address: props.address })

	const { data: gen } = useContractRead({
		address: nftCollection,
		abi: GrabCoinClubAbi,
		functionName: 'seriesOf',
		args: [stakedTokenId],
		enabled: stakedTokenId >= 0,
		select: (data: any) => data.toNumber(),
	})

	const { data: price } = useContractRead({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'tokenPrices',
		args: [ethers.constants.AddressZero, gen],
	})

	const padZero = (str: string, len?: number) => {
		len = len || 2
		var zeros = new Array(len).join('0')
		return (zeros + str).slice(-len)
	}

	const getTextColor = (hex: string, bw: boolean) => {
		if (hex.indexOf('#') === 0) {
			hex = hex.slice(1)
		}
		// convert 3-digit hex to 6-digits.
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
		}
		if (hex.length !== 6) {
			throw new Error('Invalid HEX color.')
		}
		let r = parseInt(hex.slice(0, 2), 16),
			g = parseInt(hex.slice(2, 4), 16),
			b = parseInt(hex.slice(4, 6), 16)
		if (bw) {
			return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
		}

		// pad each with zeros and return
		return (
			'#' +
			padZero((255 - r).toString(16)) +
			padZero((255 - g).toString(16)) +
			padZero((255 - b).toString(16))
		)
	}

	const textColor = getTextColor(props.bgColor, true)

	return (
		<g onClick={() => openFColModalNode({ address: props.address })}>
			<rect
				x={x}
				y={y}
				width={width}
				height={height}
				style={{
					fill: props.bgColor,
					strokeWidth: '1',
					stroke: props.bgColor,
				}}
			/>

			<g
				transform={'translate(' + (x + width / 2 - 20) + ', ' + (y + 15) + ')'}
			>
				<svg
					width="34"
					height="34"
					viewBox="0 0 34 34"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					{ensAvatar ? (
						<image href={ensAvatar} height={15} width={23} />
					) : (
						<path
							d="M17.0155 0.507813C12.6189 0.507813 8.40231 2.25436 5.29343 5.36324C2.18454 8.47211 0.437988 12.6887 0.437988 17.0853C0.437988 21.4819 2.18454 25.6984 5.29343 28.8073C8.40231 31.9162 12.6189 33.6627 17.0155 33.6627C20.7985 33.6777 24.4701 32.3836 27.4078 30.0001C30.081 27.8492 32.0209 24.9218 32.96 21.6218C33.8991 18.3217 33.7911 14.8116 32.6508 11.5755C31.5105 8.33944 29.3941 5.53695 26.5937 3.55459C23.7932 1.57223 20.4466 0.50769 17.0155 0.507813ZM17.0151 6.31307C18.0804 6.31306 19.1217 6.62895 20.0075 7.22078C20.8932 7.81261 21.5836 8.6538 21.9912 9.63797C22.3989 10.6222 22.5056 11.7051 22.2977 12.7499C22.0899 13.7947 21.5769 14.7544 20.8237 15.5077C20.0704 16.2609 19.1107 16.7739 18.0659 16.9817C17.0211 17.1896 15.9382 17.0829 14.954 16.6752C13.9698 16.2676 13.1286 15.5772 12.5368 14.6915C11.9449 13.8058 11.629 12.7644 11.629 11.6991C11.629 10.2707 12.1965 8.9007 13.2066 7.89062C14.2167 6.88053 15.5866 6.31307 17.0151 6.31307ZM27.6376 26.7583C27.1387 27.3056 26.5984 27.8138 26.0216 28.2783C23.1727 30.5749 19.5542 31.6966 15.9058 31.4141C12.2574 31.1316 8.85472 29.4662 6.39338 26.7583C6.1559 26.4982 5.93208 26.2283 5.7158 25.9535C6.59765 22.301 11.3185 19.5092 17.0155 19.5092C22.7089 19.5092 27.4321 22.3006 28.315 25.9528C28.0988 26.2278 27.875 26.4979 27.6376 26.7583Z"
							fill={textColor}
						/>
					)}
				</svg>
			</g>
			<g>
				<text
					x={x + width / 2}
					y={y + 80}
					fontSize="13px"
					textAnchor="middle"
					fill={textColor}
					fontFamily="Montserrat"
					fontWeight="600"
				>
					{ensName ? `${ensName}` : ``}
				</text>
				<text
					x={x + width / 2}
					y={y + 100}
					fontSize="11px"
					textAnchor="middle"
					fill={textColor}
					fontFamily="Montserrat"
					fontWeight="600"
				>
					{getShortWallet(props.address)}
				</text>
				<text
					x={x + width / 2}
					y={y + 120}
					fontSize="11px"
					textAnchor="middle"
					fill={textColor}
					fontFamily="Montserrat"
					fontWeight="400"
				>
					{gen && price
						? `Gen ${gen + 1} - ${ethers.utils.formatEther(
								price as BigNumber
						  )} MATIC`
						: ''}
				</text>
			</g>
		</g>
	)
}
