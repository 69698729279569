import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useModalProps } from 'react-simple-modal-provider'
import { BigNumber, ethers } from 'ethers'
import {
	Address,
	useContractRead,
	useContractWrite,
	usePrepareContractWrite,
	useWaitForTransaction,
} from 'wagmi'
import { useContract } from 'src/hooks/useContract'
import { FCOL_ADDRESS, FCOL_NFT_PRICE_LIST } from 'src/constants'
import GrabCoinClubAbi from 'src/abis/GrabCoinClubAbi.json'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'

export default function FColModalUpgradeForm() {
	const { t } = useTranslation()
	const { tokenId, close } = useModalProps('FColModalUpgrade')
	const { nftCollection } = useContract()

	const [gen, setGen] = useState<number>(0)

	const { data: series } = useContractRead({
		address: nftCollection,
		abi: GrabCoinClubAbi,
		functionName: 'seriesOf',
		args: [tokenId],
	})

	const { data: price } = useContractRead({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'tokenPrices',
		args: [ethers.constants.AddressZero, series],
		select: (data: any) => data as BigNumber,
	})

	const { data: newPrice } = useContractRead({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'tokenPrices',
		args: [ethers.constants.AddressZero, gen],
		select: (data: any) => data as BigNumber,
	})

	const {
		isError: isPrepareError,
		error: prepareError,
		config,
	} = usePrepareContractWrite({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'upgrade',
		args: [tokenId, gen],
		overrides: {
			value: newPrice?.sub(price ?? BigNumber.from(0)),
		},
		enabled: Boolean(newPrice) && Boolean(price) && Boolean(gen),
	})
	const { data, error, isError, write } = useContractWrite(config)
	const { isLoading } = useWaitForTransaction({
		hash: data?.hash,
		onSuccess: () => {
			toast.success('Successfully upgraded')
			close()
			window.location.reload()
		},
	})

	const upgrade = (event: any) => {
		event.preventDefault()
		write?.()
	}

	return (
		<form className="FColModalUpgrade-form" onSubmit={(e) => upgrade(e)}>
			<select
				className="FColModalUpgrade-form_select"
				onChange={(event) => {
					setGen(Number(event.target.value))
				}}
			>
				<option key={0}>{t('fcol_nft_select')}</option>
				{FCOL_NFT_PRICE_LIST.map((item, index) => {
					return Number(series) + 1 < item.gen ? (
						<option value={item.gen - 1} key={index}>
							Gen {item.gen} - {item.price} MATIC
						</option>
					) : (
						<></>
					)
				})}
			</select>
			{(isPrepareError || isError) && (
				<div>Error: {(prepareError || error)?.message}</div>
			)}
			<div className="FColModalUpgrade-form_btn">
				<button
					className="btn btn--matic"
					disabled={!write || isLoading || isPrepareError}
				>
					{t('fcol_nft_upgrade_btn')}
					{isLoading ? '...' : ''}
				</button>
			</div>
		</form>
	)
}
