import { Trans, useTranslation } from 'react-i18next'
import './style.scss'

export default function FColPassiveBonus() {
	const { t } = useTranslation()

	return (
		<div className="FColPassiveBonus">
			<div className="FColPassiveBonus-info">
				<div className="FColPassiveBonus-info_title">
					{t('fcol_passive_bonus_title')}
				</div>
				<div className="FColPassiveBonus-info_content">
					<Trans i18nKey="fcol_passive_bonus_text" />
				</div>
			</div>
			<div className="FColPassiveBonus-content">
				<div className="FColPassiveBonus-content_title">
					Collection 1 NFT Avatars FiHunters
				</div>
				<div className="FColPassiveBonus_table">
					<table>
						<thead>
							<tr>
								<th>
									<Trans i18nKey="fcol_passive_bonus_th_gen" />
								</th>
								<th>
									<Trans i18nKey="fcol_passive_bonus_th_count" />
								</th>
								<th>
									<Trans i18nKey="fcol_passive_bonus_th_year_gen" />
								</th>
								<th>
									<Trans i18nKey="fcol_passive_bonus_th_total_gen" />
								</th>
								<th>
									<Trans i18nKey="fcol_passive_bonus_th_year_nft" />
								</th>
								<th>
									<Trans i18nKey="fcol_passive_bonus_th_total_nft" />
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td>3 000</td>
								<td>169 200</td>
								<td>507 600</td>
								<td>56.4</td>
								<td>169.2</td>
							</tr>
							<tr>
								<td>2</td>
								<td>2 800</td>
								<td>252 000</td>
								<td>756 000</td>
								<td>90</td>
								<td>270</td>
							</tr>
							<tr>
								<td>3</td>
								<td>2 300</td>
								<td>361 560</td>
								<td>1 084 680</td>
								<td>157.2</td>
								<td>471.6</td>
							</tr>
							<tr>
								<td>4</td>
								<td>2 000</td>
								<td>585 600</td>
								<td>1 756 800</td>
								<td>292.8</td>
								<td>878.4</td>
							</tr>
							<tr>
								<td>5</td>
								<td>1 700</td>
								<td>956 760</td>
								<td>2 870 280</td>
								<td>562.8</td>
								<td>1 688.4</td>
							</tr>
							<tr>
								<td>6</td>
								<td>1 500</td>
								<td>1 350 000</td>
								<td>4 050 000</td>
								<td>900</td>
								<td>2 700</td>
							</tr>
							<tr>
								<td>7</td>
								<td>1 000</td>
								<td>1 575 600</td>
								<td>4 726 800</td>
								<td>1 575.6</td>
								<td>4 726.8</td>
							</tr>
							<tr>
								<td>8</td>
								<td>550</td>
								<td>1 609 080</td>
								<td>4 827 240</td>
								<td>2 925.6</td>
								<td>8 776.8</td>
							</tr>
							<tr>
								<td>9</td>
								<td>300</td>
								<td>1 687 680</td>
								<td>5 063 040</td>
								<td>5625.6</td>
								<td>16876.8</td>
							</tr>
							<tr>
								<td>10</td>
								<td>200</td>
								<td>1 800 000</td>
								<td>5 400 000</td>
								<td>9 000</td>
								<td>27 000</td>
							</tr>
							<tr>
								<td>11</td>
								<td>100</td>
								<td>1 575 000</td>
								<td>4 725 000</td>
								<td>15 750</td>
								<td>47 250</td>
							</tr>
							<tr>
								<td>12</td>
								<td>50</td>
								<td>1 462 500</td>
								<td>4 387 500</td>
								<td>29 250</td>
								<td>87 750</td>
							</tr>
							<tr>
								<td>13</td>
								<td>25</td>
								<td>1 406 250</td>
								<td>4 218 750</td>
								<td>56 250</td>
								<td>168 750</td>
							</tr>
							<tr>
								<td>14</td>
								<td>20</td>
								<td>1 800 000</td>
								<td>5 400 000</td>
								<td>90 000</td>
								<td>270 000</td>
							</tr>
							<tr>
								<td>15</td>
								<td>10</td>
								<td>1 575 000</td>
								<td>4 725 000</td>
								<td>157 500</td>
								<td>472 500</td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td>54 498 690</td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}
