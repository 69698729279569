import { createContext, ReactNode, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { Address, useAccount, useContractRead, useContractReads } from 'wagmi'

import { ContractValuesType } from './types'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'
import GrabCoinClubAbi from 'src/abis/GrabCoinClubAbi.json'
import persistentStorage from 'src/services/persistentStorage'

const defaultProvider: ContractValuesType = {
	contractAddress: ethers.constants.AddressZero,
	setContractAddress: () => null,
	stakedTokenId: -1,
}

const ContractContext = createContext(defaultProvider)

type Props = {
	children: ReactNode
}

const ContractProvider = ({ children }: Props) => {
	const [contractAddress, setContractAddress] = useState<Address>(
		persistentStorage.getItem('contractAddress') ?? ethers.constants.AddressZero
	)

	const { address } = useAccount()

	const { data: nftCollection } = useContractRead({
		address: contractAddress,
		abi: OpenSmartAbi,
		functionName: 'nftCollection',
		select: (data: any) => data as Address,
		enabled: contractAddress !== ethers.constants.AddressZero,
	})

	const { data: isApprovedForAll, refetch: refetchIsApprovedForAll } =
		useContractRead({
			address: nftCollection,
			abi: GrabCoinClubAbi,
			functionName: 'isApprovedForAll',
			args: [address, contractAddress],
			select: (data: any) => data as boolean,
			enabled: Boolean(nftCollection) && Boolean(address),
		})

	const { data: referrer, refetch: refetchReferrer } = useContractRead({
		address: contractAddress,
		abi: OpenSmartAbi,
		functionName: 'getLinearNode',
		args: [address],
		select: (data: any) => data.referrer as Address,
		enabled: Boolean(nftCollection) && Boolean(address),
	})

	const { data: tokens, refetch: refetchTokens } = useContractReads({
		contracts: [
			{
				address: nftCollection,
				abi: GrabCoinClubAbi,
				functionName: 'tokensOfOwner',
				args: [address],
			},
			{
				address: contractAddress,
				abi: OpenSmartAbi,
				functionName: 'stakers',
				args: [address],
			},
		],
		select: (data: any) => data as Array<any>,
		enabled: Boolean(nftCollection) && Boolean(address),
	})

	const stakedTokenId = tokens?.[1]?.isStaking ? tokens?.[1]?.tokenId : -1

	useEffect(() => {
		persistentStorage.setItem('contractAddress', contractAddress)
	}, [contractAddress])

	const values = {
		contractAddress,
		setContractAddress,
		nftCollection,
		isApprovedForAll,
		refetchIsApprovedForAll,
		referrer,
		refetchReferrer,
		tokens,
		refetchTokens,
		stakedTokenId,
	}

	return (
		<ContractContext.Provider value={values}>
			{children}
		</ContractContext.Provider>
	)
}

export { ContractContext, ContractProvider }
