import Modal, { useModalState } from 'react-simple-modal-provider'
import ModalContent from './content'

export default function NftMyMftWithdrawModal({ children }) {
	const [isOpen, setOpen] = useModalState()

	return (
		<Modal
			id={'NftMyMftWithdrawModal'}
			consumer={children}
			isOpen={isOpen}
			setOpen={setOpen}
			draggable={false}
			duration={300}
		>
			<ModalContent />
		</Modal>
	)
}
