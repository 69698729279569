import React from 'react'
import Providers from './Providers'
import AppRouter from './router'
import './i18n/config'

export default function App() {
	return (
		<Providers>
			<AppRouter />
		</Providers>
	)
}
