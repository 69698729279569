import React from 'react'

import Seo from './Seo'

export default function Page({ title, description, meta, children }) {
	return (
		<>
			<Seo title={title} description={description} meta={meta ? meta : []} />

			<main>{children}</main>
		</>
	)
}
