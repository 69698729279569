import { useModalProps } from 'react-simple-modal-provider'

import { useTranslation } from 'react-i18next'
import { Address, useContractWrite, usePrepareContractWrite } from 'wagmi'
import { FCOL_ADDRESS } from 'src/constants'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'
import './style.scss'

export default function NftMyMftWithdrawModalContent() {
	const { id, close } = useModalProps('NftMyMftWithdrawModal')

	const { config: unstakeConfig } = usePrepareContractWrite({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'unstake',
		args: [id],
	})
	const { write: unstakeWrite } = useContractWrite(unstakeConfig)

	const closeModal = () => {
		close()
	}

	const { t } = useTranslation()

	const handleClick = () => {
		unstakeWrite?.()
		close()
	}

	return (
		<div className="modal FColWithdrawModal">
			<div className="modal_body">
				<div className="FColWithdrawModal_title">
					{t('fcol_myNft_withdraw_confirm_title')}
				</div>
				<div className="FColWithdrawModal_text">
					{t('fcol_myNft_withdraw_confirm_text1')}
				</div>
				<div className="FColWithdrawModal_text">
					{t('fcol_myNft_withdraw_confirm_text2')}
				</div>
				<div className="FColWithdrawModal_text">
					{t('fcol_myNft_withdraw_confirm_text3')}
				</div>
				<div className="FColWithdrawModal_footer">
					<button
						className="FColWithdrawModal_button_error"
						onClick={closeModal}
					>
						{t('fcol_myNft_withdraw_confirm_cancel')}
					</button>
					<button
						className="FColWithdrawModal_button_success"
						onClick={handleClick}
					>
						{t('fcol_myNft_withdraw_confirm_ok')}
					</button>
				</div>
			</div>
		</div>
	)
}
