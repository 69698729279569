import { BigNumber } from 'ethers'
import FColNft from './card'
import { useContract } from 'src/hooks/useContract'
import './style.scss'

export default function FColNftMyNFT() {
	const { tokens } = useContract()

	return (
		<div className="FColNftMyMint">
			<div className="FColNftMyMint-row">
				{tokens?.[1]?.[0] && <FColNft key={0} id={tokens?.[1]?.[1]} />}
				{tokens?.[0]?.map((item: BigNumber, index: number) => (
					<FColNft key={index + 1} id={item.toNumber()} />
				))}
			</div>
		</div>
	)
}
