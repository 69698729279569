import React from 'react'
import { useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import Page from 'src/components/Page'
import Intro from '../components/Intro'
import { useTranslation } from 'react-i18next'
import Hero from '../components/Hero'
import Collection from '../components/Collection'
import { ethers } from 'ethers'

export default function HomePage() {
	const { t } = useTranslation()
	const params = useParams()

	if (params.inviter && ethers.utils.isAddress(params.inviter)) {
		Cookies.set('inviter', params.inviter)
	}

	return (
		<Page title={t('home_page_title')}>
			<Hero />
			<Intro />
			<Collection />
		</Page>
	)
}
