import Page from 'src/components/Page'
import { useTranslation } from 'react-i18next'
import SelectContract from '../components/SelectContract'

export default function AccountPage() {
	const { t } = useTranslation()

	return (
		<Page title={t('account_page_title')}>
			<div className="AccountPage">
				<div className="container">
					<SelectContract />
				</div>
			</div>
		</Page>
	)
}
