import Page from 'src/components/Page'
import { useTranslation } from 'react-i18next'
import FColBinarBonus from '../components/BinarBonus'
import FColBinarStructure from '../components/BinarStructure'
import Layout from '../components/Layout/Layout'
import FColTitle from '../components/Title'

export default function FColBinarPage() {
	const { t } = useTranslation()

	return (
		<Page title={t('fcol_binar_page_title')}>
			<Layout>
				<div className="FColBinarPage">
					<FColTitle>{t('fcol_binar_page_title')}</FColTitle>
					<FColBinarBonus />
				</div>
			</Layout>
			<FColBinarStructure />
		</Page>
	)
}
