import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomeRouter from 'src/features/Home/router'
import AccountRouter from 'src/features/Account/router'
import NotFoundPage from 'src/pages/NotFoundPage'
import Header from 'src/components/Layout/Header'
import Footer from 'src/components/Layout/Footer'

function AppRouter() {
	return (
		<BrowserRouter>
			<Header />
			<Routes>
				{HomeRouter}
				{AccountRouter}
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	)
}

export default AppRouter
