import './Footer.scss'
import { useTranslation } from 'react-i18next'
import LangSwitcher from '../LangSwitcher/LangSwitcher'

export default function Footer() {
	const { t } = useTranslation()

	return (
		<footer className="Footer">
			<div className="container">
				<div className="Footer_bottom">
					<div>
						Copyright © {new Date().getFullYear()}, GrabCoinClub.{' '}
						{t('footer_copyright')}
					</div>
					<LangSwitcher />
				</div>
			</div>
		</footer>
	)
}
