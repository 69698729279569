import { Trans } from 'react-i18next'
import './style.scss'

export default function Hero() {
	return (
		<div
			className="Hero"
			style={{
				backgroundImage:
					'url("' + process.env.PUBLIC_URL + '/img/hero-bg.png")',
			}}
		>
			<div className="container">
				<div className="Hero_wrap">
					<div className="Hero_content">
						<h1 className="Hero-title">Opensmart</h1>
						<h3 className="Hero-content">
							<Trans i18nKey="home_hero_text" />
						</h3>
					</div>
				</div>
			</div>
		</div>
	)
}
