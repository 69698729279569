import React from 'react'
import { useTranslation } from 'react-i18next'

export default function ErrorComponent() {
	const { t } = useTranslation()

	return (
		<div className="page404">
			<div className="container">
				<h1>{t('page404_title')}</h1>
				<h2>{t('page404_text')}</h2>
			</div>
		</div>
	)
}
