import React, { useState, useEffect } from 'react'
import { Address, useAccount } from 'wagmi'
import FColLinearStructureItem from './item'
import './style.scss'

export const itemConfig = {
	width: 186,
	height: 141,
	margin: 30,
	canvasMargin: 40,
}

export const levelNum = 4

interface Props {
	width: number
	height: number
}

export default function FColLinearStructureCanvas(props: Props) {
	const { address } = useAccount()

	const [width, setWidth] = useState(props.width)
	const [height, setHeight] = useState(props.height)
	const [childNum, setChildNum] = useState<number>(1)
	const [lastLvl, setLastLvl] = useState<number>(levelNum)
	const [parentX, setParentX] = useState<number>(0)

	const lvl = 0
	const childNumberArray = {} as Record<string, number>
	const minChildNum = 4

	useEffect(() => {
		setWidth(
			(itemConfig.width + itemConfig.margin) * Math.max(childNum, minChildNum)
		)
		setHeight(
			(itemConfig.height + itemConfig.margin) * lastLvl +
				itemConfig.canvasMargin * 2
		)
		setParentX((width - (itemConfig.width + itemConfig.margin) * childNum) / 2)
	}, [childNum, lastLvl, width])

	const handlerChildCount = (id: number, n: number, lvl: number) => {
		setChildNum(n)
		setLastLvl(lvl)
	}

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="svg_root"
			x={0}
			y={0}
			width={props.width}
			height={(height * props.width) / width}
			viewBox={'0, 0, ' + width + ', ' + height}
			fill={'transparent'}
		>
			<FColLinearStructureItem
				lvl={lvl}
				index={0}
				address={address as Address}
				canvasWidth={width}
				parentX={parentX}
				parentDX={0}
				parentNumberArray={childNumberArray}
				setChildCount={handlerChildCount}
			/>
		</svg>
	)
}
