import { Trans } from 'react-i18next'
import './style.scss'

export default function Collection() {
	return (
		<>
			<div className="Collection">
				<img
					src={process.env.PUBLIC_URL + '/img/c1-bg.png'}
					alt=""
					className="Collection-bg"
				/>
				<div className="container">
					<div className="Collection_wrap">
						<div className="Collection-content">
							<h3 className="Collection-title">
								<Trans i18nKey="collection_1_title" />
							</h3>
							<Trans i18nKey="collection_1_text_1" />
						</div>
						<div className="Collection-images">
							<img src={process.env.PUBLIC_URL + '/img/dev-2.png'} alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
