import React, { useState } from 'react'
import { HandySvg } from 'handy-svg'
import './Sidebar.scss'
import IconDropDown from 'src/icons/dropdown.svg'
interface Props {
	navIcon: string
	navText: string
	navLink: string
	children: string | JSX.Element | JSX.Element[]
}

export default function Submenu(props: Props) {
	let show = false
	const storedData = localStorage.getItem('showNftMenu')
	if (storedData) {
		const parsedData = JSON.parse(storedData)
		show = parsedData.showNftMenu
	}
	const [showSubMenu, setShowSubMenu] = useState<Boolean>(show)

	const handelClick = () => {
		const value = !showSubMenu
		const data = { showNftMenu: value }
		const jsonString = JSON.stringify(data)
		localStorage.setItem('showNftMenu', jsonString)
		setShowSubMenu(value)
	}

	return (
		<div className="Sidebar-submenu">
			<div
				className={
					window.location.pathname.indexOf(props.navLink) > 0 ? 'active' : ''
				}
			>
				<div onClick={handelClick}>
					<HandySvg src={props.navIcon} />
					<span>{props.navText}</span>
					<div className="Sidebar-submenu-dropdown">
						<img src={IconDropDown} alt="" />
					</div>
				</div>
			</div>
			<div className={showSubMenu ? 'show' : 'hide'}>{props.children}</div>
		</div>
	)
}
