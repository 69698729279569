import { Trans } from 'react-i18next'
import './style.scss'

export default function Intro() {
	return (
		<div
			className="Intro"
			// style={{
			// 	backgroundImage:
			// 		'url("' + process.env.PUBLIC_URL + '/img/intro-bg.jpg")',
			// }}
		>
			<img
				src={process.env.PUBLIC_URL + '/img/c1-bg.png'}
				alt=""
				className="Intro-bg"
			/>
			<div className="container">
				<div className="Intro_wrap">
					<div className="Intro-image">
						<img src={process.env.PUBLIC_URL + '/img/dev-1.png'} alt="" />
					</div>
					<div className="Intro-content">
						<h1 className="Intro-title">
							<Trans i18nKey="home_intro_title" />
						</h1>
						<Trans i18nKey="home_intro_text" />
					</div>
				</div>
			</div>
		</div>
	)
}
