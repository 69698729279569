import React, { useEffect, useState } from 'react'
import { BigNumber, ethers } from 'ethers'
import { Address, useContractRead } from 'wagmi'
import FColBinaryStructureNode from './node'
import { itemConfig } from './canvas'
import FColBinaryStructureControl from './control'
import { FCOL_ADDRESS } from 'src/constants'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'

interface Props {
	lvl: number
	index: number
	address: Address
	setAddress: (address: Address) => void
	canvasWidth: number
	parentX: number
	parentDX: number
	leftChildNum: number
	rightChildNum: number
	setChildCount: (id: number, n: number, lastLvl: number) => void
}

export default function FColBinaryStructureItem(props: Props) {
	const [existsLeft, setExistLeft] = useState<boolean>(false)
	const [existsRight, setExistRight] = useState<boolean>(false)
	const [leftChildNum, setLeftChildNum] = useState<number>(0)
	const [rightChildNum, setRightChildNum] = useState<number>(0)
	const [lastLvl, setLastLvl] = useState<number>(0)
	const [x, setX] = useState<number>(0)
	const [dx, setDx] = useState<number>(0)

	let lvl = props.lvl + 1
	const width = itemConfig.width
	const height = itemConfig.height
	const margin = itemConfig.margin
	const w = width + margin
	const h = height + margin
	const y = props.lvl * h + itemConfig.canvasMargin

	const { data: binaryNode } = useContractRead({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'getBinaryNode',
		args: [props.address],
		select: (data: any) => {
			return {
				direction: data[0],
				claimedAt: data[1],
				parent: data[2],
				left: data[3] as Address,
				leftTurnOver: data[4] as BigNumber,
				right: data[5] as Address,
				rightTurnOver: data[6] as BigNumber,
			}
		},
	})

	useEffect(() => {
		// let beforeNodeNum = props.index ? props.leftChildNum : 0
		let dx = 0
		setX(props.parentX + props.parentDX)
		const childNum = leftChildNum + rightChildNum
		if (lvl === 1) {
			dx = (w * childNum) / 2 - w / 2
		} else if (lvl === 2) {
			if (props.index) {
				dx = leftChildNum < 2 ? 1.5 * w : w * leftChildNum
			} else {
				dx = rightChildNum < 2 ? -1.5 * w : -w * rightChildNum
			}
		} else {
			dx = props.index ? leftChildNum * w : -rightChildNum * w
		}
		setDx(dx)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.parentX, leftChildNum, rightChildNum])

	useEffect(() => {
		if (!binaryNode) return
		setExistLeft(binaryNode.left !== ethers.constants.AddressZero)
		setExistRight(binaryNode.right !== ethers.constants.AddressZero)
	}, [binaryNode, lvl])

	useEffect(() => {
		setLeftChildNum(0.5)
	}, [existsLeft])

	useEffect(() => {
		setRightChildNum(0.5)
	}, [existsRight])

	useEffect(() => {
		props.setChildCount(
			props.index,
			(leftChildNum ? leftChildNum : 0.5) +
				(rightChildNum ? rightChildNum : 0.5),
			lastLvl
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leftChildNum, rightChildNum, lastLvl])

	const handlerChildCount = (
		index: number,
		childNum: number,
		lastLvl: number
	) => {
		if (!index) setLeftChildNum(childNum)
		if (index) setRightChildNum(childNum)

		const last = lastLvl > lvl ? lastLvl : lvl
		setLastLvl(last)
	}
	const x1 = props.parentX + props.parentDX + (props.index ? width : 0)
	const x2 = x + dx + width / 2

	return (
		<g>
			<g>
				<FColBinaryStructureNode
					address={props.address}
					x={x + dx}
					y={y}
					width={width}
					height={height}
				/>
				{lvl > 1 ? (
					<g>
						<line
							x1={x1}
							y1={y - height / 6 - margin}
							x2={props.parentX + props.parentDX === x + dx ? x1 : x2}
							y2={y - height / 6 - margin}
							style={{
								strokeWidth: '1',
								stroke: '#8247E5',
							}}
						/>
						<line
							x1={x2}
							y1={
								y -
								margin -
								(props.parentX + props.parentDX === x + dx ? 0 : height / 6)
							}
							x2={x2}
							y2={y}
							style={{
								strokeWidth: '1',
								stroke: '#8247E5',
							}}
						/>
					</g>
				) : (
					<></>
				)}

				{existsLeft ? (
					<FColBinaryStructureItem
						lvl={lvl}
						index={0}
						address={binaryNode?.left as Address}
						setAddress={props.setAddress}
						canvasWidth={props.canvasWidth}
						parentX={x}
						parentDX={dx}
						leftChildNum={leftChildNum}
						rightChildNum={rightChildNum}
						setChildCount={handlerChildCount}
					/>
				) : (
					<></>
				)}
				{existsRight ? (
					<FColBinaryStructureItem
						lvl={lvl}
						index={1}
						address={binaryNode?.right as Address}
						setAddress={props.setAddress}
						canvasWidth={props.canvasWidth}
						parentX={x}
						parentDX={dx}
						leftChildNum={leftChildNum}
						rightChildNum={rightChildNum}
						setChildCount={handlerChildCount}
					/>
				) : (
					<></>
				)}
			</g>
			<g>
				{lvl === 1 ? (
					<FColBinaryStructureControl
						width={Math.max(props.canvasWidth, width * 4)}
						childX={w * 3}
						address={props.address}
						binaryNode={binaryNode}
						setAddress={props.setAddress}
					/>
				) : (
					<></>
				)}
			</g>
		</g>
	)
}
