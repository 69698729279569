import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Cookies from 'js-cookie'

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		fallbackLng: 'en',
		lng: Cookies.get('locale') || 'en',
		resources: {
			en: {
				translations: require('./locales/en/translations.json'),
			},
			ru: {
				translations: require('./locales/ru/translations.json'),
			},
		},
		ns: ['translations'],
		defaultNS: 'translations',
		interpolation: {
			escapeValue: false,
		},
	})

i18n.languages = ['en', 'ru']

export default i18n
