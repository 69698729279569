import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BigNumber, ethers } from 'ethers'
import { Address, useContractRead, useContractReads } from 'wagmi'
import './style.scss'
import { FCOL_ADDRESS, FCOL_STATISTICS_ADDRESS } from 'src/constants'
import OpenSmartStatisticsAbi from 'src/abis/OpenSmartStatistics.json'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'

interface Props {
	address: Address
}

export default function FColBinarStructureStat(props: Props) {
	const { t } = useTranslation()
	const [countDown, setCountDown] = useState(0)

	const { data: binaryNode } = useContractRead({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'getBinaryNode',
		args: [props.address],
		select: (data: any) => {
			return {
				direction: data[0],
				claimedAt: data[1],
				parent: data[2],
				left: data[3] as Address,
				leftTurnOver: data[4] as BigNumber,
				right: data[5] as Address,
				rightTurnOver: data[6] as BigNumber,
			}
		},
	})

	const { data } = useContractReads({
		contracts: [
			{
				address: FCOL_STATISTICS_ADDRESS as Address,
				abi: OpenSmartStatisticsAbi,
				functionName: 'calcBinaryChildStatistics',
				args: [
					props.address,
					binaryNode?.left ?? ethers.constants.AddressZero,
					[0, 0, 0, 0],
				],
			},
			{
				address: FCOL_STATISTICS_ADDRESS as Address,
				abi: OpenSmartStatisticsAbi,
				functionName: 'calcBinaryChildStatistics',
				args: [
					props.address,
					binaryNode?.right ?? ethers.constants.AddressZero,
					[0, 0, 0, 0],
				],
			},
			{
				address: FCOL_ADDRESS as Address,
				abi: OpenSmartAbi,
				functionName: 'calcBinaryReward',
				args: [props.address],
			},
		],
		select: (data: any) => data,
	})

	useEffect(() => {
		setCountDown(86400 * 30)
	}, [])
	useEffect(() => {
		const interval = setInterval(() => {
			if (countDown) {
				setCountDown(countDown - 1)
			} else {
				clearInterval(interval)
			}
		}, 1000)

		return () => clearInterval(interval)
	}, [countDown])

	return (
		<div className="FColBinarStructure_stat">
			<div className="FColBinarStructure_stat_container">
				<div className="FColBinarStructure_stat_header">
					{t('fcol_binar_stat_left_branch')}
				</div>
				<div className="FColBinarStructure_stat_item">
					<div className="FColBinarStructure_stat_label">
						{t('fcol_binar_stat_personal_turnover')}
					</div>
					<div className="FColBinarStructure_stat_value">
						{`${ethers.utils.formatEther(data?.[0]?.[0] ?? 0)} MATIC`}
					</div>
				</div>
				<div className="FColBinarStructure_stat_item">
					<div className="FColBinarStructure_stat_label">
						{t('fcol_binar_stat_structure_turnover')}
					</div>
					<div className="FColBinarStructure_stat_value">
						{`${ethers.utils.formatEther(data?.[0]?.[1] ?? 0)} MATIC`}
					</div>
				</div>
				<div className="FColBinarStructure_stat_item">
					<div className="FColBinarStructure_stat_label">
						{t('fcol_binar_stat_actual_turnover')}
					</div>
					<div className="FColBinarStructure_stat_value">
						{`${ethers.utils.formatEther(
							(data?.[0]?.[2] ?? BigNumber.from(0)).add(
								binaryNode?.leftTurnOver ?? BigNumber.from(0)
							)
						)} MATIC`}
					</div>
				</div>
				<div className="FColBinarStructure_stat_item">
					<div className="FColBinarStructure_stat_label">
						{t('fcol_binar_stat_partners')}
					</div>
					<div className="FColBinarStructure_stat_value">
						{data?.[0]?.[3].toNumber() ?? 0}
					</div>
				</div>
			</div>
			<div className="FColBinarStructure_stat_center">
				<div className="FColBinarStructure_stat_center_container">
					<div className="FColBinarStructure_stat_item">
						<div className="FColBinarStructure_stat_label">
							{t('fcol_binar_stat_total_turnover')}
						</div>
						<div className="FColBinarStructure_stat_value">
							{`${ethers.utils.formatEther(
								(data?.[0]?.[1] || BigNumber.from(0)).add(
									data?.[1]?.[1] ?? BigNumber.from(0)
								)
							)} MATIC`}
						</div>
					</div>
					<div className="FColBinarStructure_stat_item">
						<div className="FColBinarStructure_stat_label">
							{t('fcol_binar_stat_binary_bonus')}
						</div>
						<div className="FColBinarStructure_stat_value">
							{`${ethers.utils.formatEther(data?.[2]?.[0] ?? 0)} MATIC`}
						</div>
					</div>
					<div className="FColBinarStructure_stat_item">
						<div className="FColBinarStructure_stat_label">
							{t('fcol_binar_stat_partners')}
						</div>
						<div className="FColBinarStructure_stat_value">
							{(data?.[0]?.[3].toNumber() ?? 0) +
								(data?.[1]?.[3].toNumber() ?? 0)}
						</div>
					</div>
				</div>
				<div className="FColBinarStructure_stat_center_container">
					<div className="FColBinarStructure_stat_center_item">
						<div className="FColBinarStructure_stat_center_time">
							{t('fcol_binar_stat_time_left')}
						</div>
						<div className="FColBinarStructure_stat_center_time">
							<div className="FColBinarStructure_stat_center_value">
								{Math.floor(countDown / 86400)}
								{' days '}
							</div>
							<div className="FColBinarStructure_stat_center_value">
								{Math.floor((countDown % 86400) / 3600)}
								{' : '}
								{Math.floor(((countDown % 86400) % 3600) / 60) < 10 ? '0' : ''}
								{Math.floor(((countDown % 86400) % 3600) / 60)}
								{' : '}
								{Math.floor((countDown % 86400) % 60) < 10 ? '0' : ''}
								{Math.floor((countDown % 86400) % 60)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="FColBinarStructure_stat_container">
				<div className="FColBinarStructure_stat_header">
					{t('fcol_binar_stat_right_branch')}
				</div>
				<div className="FColBinarStructure_stat_item">
					<div className="FColBinarStructure_stat_label">
						{t('fcol_binar_stat_personal_turnover')}
					</div>
					<div className="FColBinarStructure_stat_value">
						{`${ethers.utils.formatEther(data?.[1]?.[0] ?? 0)} MATIC`}
					</div>
				</div>
				<div className="FColBinarStructure_stat_item">
					<div className="FColBinarStructure_stat_label">
						{t('fcol_binar_stat_structure_turnover')}
					</div>
					<div className="FColBinarStructure_stat_value">
						{`${ethers.utils.formatEther(data?.[1]?.[1] ?? 0)} MATIC`}
					</div>
				</div>
				<div className="FColBinarStructure_stat_item">
					<div className="FColBinarStructure_stat_label">
						{t('fcol_binar_stat_actual_turnover')}
					</div>
					<div className="FColBinarStructure_stat_value">
						{`${ethers.utils.formatEther(
							(data?.[1]?.[2] ?? BigNumber.from(0)).add(
								binaryNode?.rightTurnOver ?? BigNumber.from(0)
							)
						)} MATIC`}
					</div>
				</div>
				<div className="FColBinarStructure_stat_item">
					<div className="FColBinarStructure_stat_label">
						{t('fcol_binar_stat_partners')}
					</div>
					<div className="FColBinarStructure_stat_value">
						{data?.[1]?.[3].toNumber() ?? 0}
					</div>
				</div>
			</div>
		</div>
	)
}
