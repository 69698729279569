import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isShowMobileSidebar: false,
}

export const mobileSidebarSlice = createSlice({
	name: 'mobileSidebar',
	initialState,

	reducers: {
		toggleMobileSidebar: (state) => {
			state.isShowMobileSidebar = !state.isShowMobileSidebar
		},
	},
})

export const { toggleMobileSidebar } = mobileSidebarSlice.actions

export const isShowMobileSidebar = (state) =>
	state.mobileSidebar.isShowMobileSidebar

export default mobileSidebarSlice.reducer
