import { Trans, useTranslation } from 'react-i18next'
import './style.scss'

export default function FColBinarBonus() {
	const { t } = useTranslation()
	return (
		<div className="FColBinarBonus">
			<div className="FColBinarBonus-content">
				<Trans i18nKey="fcol_binar_bonus_text" />
			</div>
			<div className="FColBinarBonus_table">
				<div className="FColBinarBonus_table-title">
					{t('fcol_binar_bonus_table_title')}
				</div>
				<div className="FColBinarBonus_table-wrap">
					<table>
						<tbody>
							<tr>
								<th>Gen 1</th>
								<th>Gen 2</th>
								<th>Gen 3</th>
								<th>Gen 4</th>
								<th>Gen 5</th>
								<th>Gen 6</th>
								<th>Gen 7</th>
								<th>Gen 8</th>
								<th>Gen 9</th>
								<th>Gen 10</th>
								<th>Gen 11</th>
								<th>Gen 12</th>
								<th>Gen 13</th>
								<th>Gen 14</th>
								<th>Gen 15</th>
							</tr>
							<tr>
								<td>6%</td>
								<td>6%</td>
								<td>7%</td>
								<td>7%</td>
								<td>8%</td>
								<td>8%</td>
								<td>9%</td>
								<td>9%</td>
								<td>10%</td>
								<td>10%</td>
								<td>11%</td>
								<td>11%</td>
								<td>12%</td>
								<td>12%</td>
								<td>12%</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<p>{t('fcol_binar_bonus_text_')}</p>
		</div>
	)
}
