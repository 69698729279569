import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import {
	Address,
	useAccount,
	useContractReads,
	useContractWrite,
	usePrepareContractWrite,
} from 'wagmi'
import { useTranslation } from 'react-i18next'
import { itemConfig } from './canvas'
import './style.scss'
import { FCOL_ADDRESS } from 'src/constants'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'

interface Props {
	width: number
	childX: number
	address: Address
	binaryNode: any
	setAddress: (address: Address) => void
}

export default function FColBinaryStructureControl(props: Props) {
	const { t } = useTranslation()
	const [existsLeft, setExistLeft] = useState<boolean>(false)
	const [existsRight, setExistRight] = useState<boolean>(false)
	const [direction, setDirection] = useState<number>(0)
	const [writeDirection, setWriteDirection] = useState<boolean>(false)

	const { address } = useAccount()
	const width = props.width
	const [countDown, setCountDown] = useState(0)
	const d = 14
	const y = 0
	const dy = -15

	const { data } = useContractReads({
		contracts: [
			{
				address: FCOL_ADDRESS as Address,
				abi: OpenSmartAbi,
				functionName: 'getLastLeftBinaryNode',
				args: [props.address],
			},
			{
				address: FCOL_ADDRESS as Address,
				abi: OpenSmartAbi,
				functionName: 'getLastRightBinaryNode',
				args: [props.address],
			},
			{
				address: FCOL_ADDRESS as Address,
				abi: OpenSmartAbi,
				functionName: 'getCurrentTimestamp',
			},
		],
		select: (data: any) => data,
	})

	const { config } = usePrepareContractWrite({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'setBinaryDirection',
		args: [direction],
	})
	const { write } = useContractWrite({
		...config,
		onError: () => {
			setDirection(props.binaryNode?.direction.toNumber())
		},
	})

	useEffect(() => {
		if (!props.binaryNode) return
		setExistLeft(props.binaryNode.left !== ethers.constants.AddressZero)
		setExistRight(props.binaryNode.right !== ethers.constants.AddressZero)
		setDirection(props.binaryNode.direction)
	}, [props.binaryNode])

	useEffect(() => {
		const interval = setInterval(() => {
			if (countDown) {
				setCountDown(countDown - 1)
			} else {
				clearInterval(interval)
			}
		}, 1000)

		return () => clearInterval(interval)
	}, [countDown])

	const MoveAddressClick = (address?: Address) => {
		if (address) props.setAddress(address)
	}

	const handleDirection = (type: number) => {
		setDirection(type)
		setWriteDirection(true)
	}

	useEffect(() => {
		if (writeDirection && write) {
			write()
			setWriteDirection(false)
		}
	}, [write, writeDirection])

	useEffect(() => {
		if (props.binaryNode && data) {
			const currentTimestamp = data[2]?.toNumber()
			setCountDown(
				Math.floor(currentTimestamp / 86400 + 1) * 86400 - currentTimestamp
			)
		}
	}, [data, props.binaryNode])

	return (
		<g>
			<g
				id="home_icon"
				transform={'translate(' + (width / 2 - d - 20) + ',' + y + ')'}
				onClick={() => MoveAddressClick(address)}
				style={{ cursor: 'pointer' }}
			>
				<svg
					width="40"
					height="40"
					viewBox="0 0 40 40"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="20" cy="20" r="20" fill="#8247E5" />
					<path
						d="M28.8296 18.7858C28.8292 18.7854 28.8288 18.7849 28.8284 18.7845L21.2138 11.1703C20.8893 10.8456 20.4578 10.6667 19.9988 10.6667C19.5398 10.6667 19.1082 10.8454 18.7835 11.1701L11.173 18.7805C11.1704 18.7831 11.1679 18.7858 11.1653 18.7884C10.4988 19.4587 10.4999 20.5463 11.1686 21.215C11.4741 21.5206 11.8775 21.6976 12.3089 21.7161C12.3264 21.7178 12.3441 21.7187 12.3619 21.7187H12.6654V27.3223C12.6654 28.4312 13.5676 29.3334 14.6767 29.3334H17.6557C17.9577 29.3334 18.2026 29.0885 18.2026 28.7865V24.3932C18.2026 23.8872 18.6142 23.4757 19.1202 23.4757H20.8773C21.3833 23.4757 21.7949 23.8872 21.7949 24.3932V28.7865C21.7949 29.0885 22.0397 29.3334 22.3418 29.3334H25.3208C26.4299 29.3334 27.3322 28.4312 27.3322 27.3223V21.7187H27.6136C28.0724 21.7187 28.5039 21.54 28.8288 21.2153C29.4981 20.5455 29.4984 19.456 28.8296 18.7858Z"
						fill="white"
					/>
				</svg>
			</g>
			{address === props.address ? (
				<g
					id="auto_button"
					onClick={() => handleDirection(0)}
					style={{
						cursor: 'pointer',
					}}
				>
					<rect
						x={width / 2 - d - 100}
						y={y + 60}
						width={200}
						height={48}
						style={{
							fill: '#8247E5',
							strokeWidth: '4',
							stroke: direction === 0 ? '#afa0c9' : '#8247E5',
						}}
					/>
					<text
						x={width / 2 - d}
						y={y + 90}
						fontSize="18px"
						textAnchor="middle"
						fill="#FFFFFF"
						fontFamily="Circe"
						fontWeight="700"
					>
						{t('fcol_binar_auto_direction')}
					</text>
				</g>
			) : (
				<></>
			)}
			<g id="left_up">
				<g
					transform={
						'translate(' + (width / 2 - d - 160) + ',' + (y + 60) + ')'
					}
					onClick={() =>
						MoveAddressClick(
							address !== props.address ? props.binaryNode.parent : null
						)
					}
					style={{ cursor: address !== props.address ? 'pointer' : 'auto' }}
				>
					<svg
						width="16"
						height="10"
						viewBox="0 0 16 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.40542 0.865312L15.7561 8.27271C15.9968 8.5154 15.9964 8.90833 15.7548 9.15062C15.5133 9.39272 15.1221 9.39209 14.8812 9.14937L7.96807 2.18288L1.05496 9.14962C0.814048 9.39231 0.423079 9.39294 0.181545 9.15087C0.0604668 9.02941 -7.34274e-05 8.8703 -7.34256e-05 8.71118C-7.34237e-05 8.55247 0.0600615 8.39398 0.1803 8.27274L7.53076 0.865312C7.64648 0.748423 7.80398 0.68283 7.96807 0.68283C8.13217 0.68283 8.28948 0.748609 8.40542 0.865312Z"
							fill={address !== props.address ? '#21E786' : '#505050'}
						/>
					</svg>
					<circle
						cx="10"
						cy="8"
						r="20"
						stroke="transparent"
						strokeWidth="3"
						fill="transparent"
					/>
				</g>
				<text
					x={width / 2 - d - 200}
					y={y + 70}
					fontSize="14px"
					textAnchor="end"
					fill="#FFFFFF"
					fontFamily="Circe"
					fontWeight="400"
				>
					{t('fcol_binar_structure_up')}
				</text>
			</g>
			<g id="left_down">
				<g
					transform={
						'translate(' + (width / 2 - d - 160) + ',' + (y + 120) + ')'
					}
					onClick={() =>
						MoveAddressClick(existsLeft ? props.binaryNode.left : null)
					}
					style={{ cursor: existsLeft ? 'pointer' : 'auto' }}
				>
					<svg
						width="16"
						height="10"
						viewBox="0 0 16 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.40542 9.14964L15.7561 1.74225C15.9968 1.49955 15.9964 1.10662 15.7548 0.864334C15.5133 0.622234 15.1221 0.622859 14.8812 0.865585L7.96807 7.83207L1.05496 0.865335C0.814048 0.622641 0.423079 0.622015 0.181545 0.864084C0.0604668 0.985541 -7.34274e-05 1.14466 -7.34256e-05 1.30377C-7.34237e-05 1.46249 0.0600615 1.62098 0.1803 1.74221L7.53076 9.14964C7.64648 9.26653 7.80398 9.33212 7.96807 9.33212C8.13217 9.33212 8.28948 9.26634 8.40542 9.14964Z"
							fill={existsLeft ? '#21E786' : '#505050'}
						/>
					</svg>
					<circle
						cx="10"
						cy="8"
						r="20"
						stroke="transparent"
						strokeWidth="3"
						fill="transparent"
					/>
				</g>
				<text
					x={width / 2 - d - 200}
					y={y + 130}
					fontSize="14px"
					textAnchor="end"
					fill="#FFFFFF"
					fontFamily="Circe"
					fontWeight="400"
				>
					{t('fcol_binar_structure_down')}
				</text>
			</g>
			<g id="left_end">
				<g
					transform={
						'translate(' + (width / 2 - d - 160) + ',' + (y + 180) + ')'
					}
					onClick={() => MoveAddressClick(data?.[0])}
					style={{ cursor: existsRight ? 'pointer' : 'auto' }}
				>
					<svg
						width="16"
						height="18"
						viewBox="0 0 16 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.40542 17.1569L15.7561 9.74951C15.9968 9.50681 15.9964 9.11389 15.7548 8.8716C15.5133 8.6295 15.1221 8.63012 14.8812 8.87285L7.96807 15.8393L1.05496 8.8726C0.814048 8.6299 0.423079 8.62928 0.181545 8.87135C0.0604668 8.9928 -7.34274e-05 9.15192 -7.34256e-05 9.31104C-7.34237e-05 9.46975 0.0600615 9.62824 0.1803 9.74948L7.53076 17.1569C7.64648 17.2738 7.80398 17.3394 7.96807 17.3394C8.13217 17.3394 8.28948 17.2736 8.40542 17.1569Z"
							fill={existsLeft ? '#21E786' : '#505050'}
						/>
						<path
							d="M8.40542 9.14964L15.7561 1.74225C15.9968 1.49955 15.9964 1.10662 15.7548 0.864334C15.5133 0.622234 15.1221 0.622859 14.8812 0.865585L7.96807 7.83207L1.05496 0.865335C0.814048 0.622641 0.42308 0.622015 0.181545 0.864084C0.0604667 0.985541 -7.3192e-05 1.14466 -7.31901e-05 1.30377C-7.31882e-05 1.46249 0.0600618 1.62098 0.1803 1.74221L7.53076 9.14964C7.64648 9.26653 7.80398 9.33212 7.96807 9.33212C8.13217 9.33212 8.28948 9.26634 8.40542 9.14964Z"
							fill={existsLeft ? '#21E786' : '#505050'}
						/>
					</svg>
					<circle
						cx="10"
						cy="8"
						r="20"
						stroke="transparent"
						strokeWidth="3"
						fill="transparent"
					/>
				</g>
				<text
					x={width / 2 - d - 200}
					y={y + 190}
					fontSize="14px"
					textAnchor="end"
					fill="#FFFFFF"
					fontFamily="Circe"
					fontWeight="400"
				>
					{t('fcol_binar_structure_end')}
				</text>
			</g>

			<g id="right_up">
				<g
					transform={
						'translate(' + (width / 2 - d + 145) + ',' + (y + 60) + ')'
					}
					onClick={() =>
						MoveAddressClick(
							address !== props.address ? props.binaryNode.parent : null
						)
					}
					style={{ cursor: address !== props.address ? 'pointer' : 'auto' }}
				>
					<svg
						width="16"
						height="10"
						viewBox="0 0 16 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.40542 0.865312L15.7561 8.27271C15.9968 8.5154 15.9964 8.90833 15.7548 9.15062C15.5133 9.39272 15.1221 9.39209 14.8812 9.14937L7.96807 2.18288L1.05496 9.14962C0.814048 9.39231 0.423079 9.39294 0.181545 9.15087C0.0604668 9.02941 -7.34274e-05 8.8703 -7.34256e-05 8.71118C-7.34237e-05 8.55247 0.0600615 8.39398 0.1803 8.27274L7.53076 0.865312C7.64648 0.748423 7.80398 0.68283 7.96807 0.68283C8.13217 0.68283 8.28948 0.748609 8.40542 0.865312Z"
							fill={address !== props.address ? '#21E786' : '#505050'}
						/>
					</svg>
					<circle
						cx="10"
						cy="8"
						r="20"
						stroke="transparent"
						strokeWidth="3"
						fill="transparent"
					/>
				</g>
				<text
					x={width / 2 - d + 185}
					y={y + 70}
					fontSize="14px"
					textAnchor="start"
					fill="#FFFFFF"
					fontFamily="Circe"
					fontWeight="400"
				>
					{t('fcol_binar_structure_up')}
				</text>
			</g>
			<g id="right_down">
				<g
					transform={
						'translate(' + (width / 2 - d + 145) + ',' + (y + 120) + ')'
					}
					onClick={() =>
						MoveAddressClick(existsRight ? props.binaryNode.right : null)
					}
					style={{ cursor: existsRight ? 'pointer' : 'auto' }}
				>
					<svg
						width="16"
						height="10"
						viewBox="0 0 16 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.40542 9.14964L15.7561 1.74225C15.9968 1.49955 15.9964 1.10662 15.7548 0.864334C15.5133 0.622234 15.1221 0.622859 14.8812 0.865585L7.96807 7.83207L1.05496 0.865335C0.814048 0.622641 0.423079 0.622015 0.181545 0.864084C0.0604668 0.985541 -7.34274e-05 1.14466 -7.34256e-05 1.30377C-7.34237e-05 1.46249 0.0600615 1.62098 0.1803 1.74221L7.53076 9.14964C7.64648 9.26653 7.80398 9.33212 7.96807 9.33212C8.13217 9.33212 8.28948 9.26634 8.40542 9.14964Z"
							fill={existsRight ? '#21E786' : '#505050'}
						/>
					</svg>
					<circle
						cx="10"
						cy="8"
						r="20"
						stroke="transparent"
						strokeWidth="3"
						fill="transparent"
					/>
				</g>
				<text
					x={width / 2 - d + 185}
					y={y + 130}
					fontSize="14px"
					textAnchor="start"
					fill="#FFFFFF"
					fontFamily="Circe"
					fontWeight="400"
				>
					{t('fcol_binar_structure_down')}
				</text>
			</g>
			<g id="right_end">
				<g
					transform={
						'translate(' + (width / 2 - d + 145) + ',' + (y + 180) + ')'
					}
					onClick={() => MoveAddressClick(data?.[1])}
					style={{ cursor: existsRight ? 'pointer' : 'auto' }}
				>
					<svg
						width="16"
						height="18"
						viewBox="0 0 16 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.40542 17.1569L15.7561 9.74951C15.9968 9.50681 15.9964 9.11389 15.7548 8.8716C15.5133 8.6295 15.1221 8.63012 14.8812 8.87285L7.96807 15.8393L1.05496 8.8726C0.814048 8.6299 0.423079 8.62928 0.181545 8.87135C0.0604668 8.9928 -7.34274e-05 9.15192 -7.34256e-05 9.31104C-7.34237e-05 9.46975 0.0600615 9.62824 0.1803 9.74948L7.53076 17.1569C7.64648 17.2738 7.80398 17.3394 7.96807 17.3394C8.13217 17.3394 8.28948 17.2736 8.40542 17.1569Z"
							fill={existsRight ? '#21E786' : '#505050'}
						/>
						<path
							d="M8.40542 9.14964L15.7561 1.74225C15.9968 1.49955 15.9964 1.10662 15.7548 0.864334C15.5133 0.622234 15.1221 0.622859 14.8812 0.865585L7.96807 7.83207L1.05496 0.865335C0.814048 0.622641 0.42308 0.622015 0.181545 0.864084C0.0604667 0.985541 -7.3192e-05 1.14466 -7.31901e-05 1.30377C-7.31882e-05 1.46249 0.0600618 1.62098 0.1803 1.74221L7.53076 9.14964C7.64648 9.26653 7.80398 9.33212 7.96807 9.33212C8.13217 9.33212 8.28948 9.26634 8.40542 9.14964Z"
							fill={existsRight ? '#21E786' : '#505050'}
						/>
					</svg>
					<circle
						cx="10"
						cy="8"
						r="20"
						stroke="transparent"
						strokeWidth="3"
						fill="transparent"
					/>
				</g>
				<text
					x={width / 2 - d + 185}
					y={y + 190}
					fontSize="14px"
					textAnchor="start"
					fill="#FFFFFF"
					fontFamily="Circe"
					fontWeight="400"
				>
					{t('fcol_binar_structure_end')}
				</text>
			</g>
			<g id="count_down_button">
				<rect
					x={width / 2 - d - itemConfig.width}
					y={y + itemConfig.height * 2 + 20}
					width={itemConfig.width * 2}
					height={itemConfig.height / 2}
					style={{
						fill: '#8247E5',
						strokeWidth: '1',
						stroke: '#8247E5',
					}}
				/>
				<>
					<text
						x={width / 2 - d - itemConfig.width / 2 + 20}
						y={y + itemConfig.height * 2 + 70}
						fontSize="20px"
						textAnchor="middle"
						fill="#000000"
						fontFamily="Circe"
						fontWeight="400"
					>
						{t('fcol_binar_structure_accrual')}
					</text>
					<text
						x={width / 2 - d - itemConfig.width / 2 + 20}
						y={y + itemConfig.height * 2 + 100}
						fontSize="20px"
						textAnchor="middle"
						fill="#000000"
						fontFamily="Circe"
						fontWeight="400"
					>
						{t('fcol_binar_structure_bonus')}
					</text>
					<rect
						x={width / 2 - d + itemConfig.width / 2 - 60}
						y={y + itemConfig.height * 2 + 50}
						width={itemConfig.width / 2 + 40}
						height={itemConfig.height / 4}
						style={{
							fill: 'rgba(255,255,255,0.27)',
							strokeWidth: '1',
							stroke: '#8247E5',
						}}
					/>
					<text
						x={width / 2 - d + itemConfig.width / 2 + 10}
						y={y + itemConfig.height * 2 + 85}
						fontSize="24px"
						textAnchor="middle"
						fill="#000000"
						fontFamily="Circe"
						fontWeight="700"
					>
						{Math.floor(countDown / 3600)}
						{' : '}
						{Math.floor((countDown % 3600) / 60) < 10 ? '0' : ''}
						{Math.floor((countDown % 3600) / 60)}
						{' : '}
						{Math.floor(countDown % 60) < 10 ? '0' : ''}
						{Math.floor(countDown % 60)}
					</text>
				</>
			</g>
			{address === props.address ? (
				<g onClick={() => handleDirection(2)} style={{ cursor: 'pointer' }}>
					<polygon
						points={
							width / 2 -
							d -
							props.childX / 2 +
							40 +
							',' +
							(y + itemConfig.height + dy + 55) +
							' ' +
							(width / 2 - d - props.childX / 2 + 70) +
							',' +
							(y + itemConfig.height + dy + 30) +
							' ' +
							(width / 2 - d - itemConfig.width + 50) +
							',' +
							(y + itemConfig.height + dy + 30) +
							' ' +
							(width / 2 - d - itemConfig.width + 50) +
							',' +
							(y + itemConfig.height + dy + 80) +
							' ' +
							(width / 2 - d - props.childX / 2 + 70) +
							',' +
							(y + itemConfig.height + dy + 80)
						}
						style={{
							fill: '#8247E5',
							stroke: direction === 2 ? '#afa0c9' : '#8247E5',
							strokeWidth: 4,
						}}
					/>
					<text
						x={width / 2 - d - (props.childX + itemConfig.width) / 4}
						y={y + itemConfig.height + dy + 60}
						fontSize="18px"
						textAnchor="middle"
						fill="#FFFFFF"
						fontFamily="Circe"
						fontWeight="400"
					>
						{t('fcol_binar_left_direction')}
					</text>
				</g>
			) : (
				<></>
			)}
			{address === props.address ? (
				<g onClick={() => handleDirection(1)} style={{ cursor: 'pointer' }}>
					<polygon
						points={
							width / 2 -
							d +
							props.childX / 2 -
							40 +
							',' +
							(y + itemConfig.height + dy + 55) +
							' ' +
							(width / 2 - d + props.childX / 2 - 70) +
							',' +
							(y + itemConfig.height + dy + 30) +
							' ' +
							(width / 2 - d + itemConfig.width - 50) +
							',' +
							(y + itemConfig.height + dy + 30) +
							' ' +
							(width / 2 - d + itemConfig.width - 50) +
							',' +
							(y + itemConfig.height + dy + 80) +
							' ' +
							(width / 2 - d + props.childX / 2 - 70) +
							',' +
							(y + itemConfig.height + dy + 80)
						}
						style={{
							fill: '#8247E5',
							stroke: direction === 1 ? '#afa0c9' : '#8247E5',
							strokeWidth: 4,
						}}
					/>
					<text
						x={width / 2 - d + (props.childX + itemConfig.width) / 4}
						y={y + itemConfig.height + dy + 60}
						fontSize="18px"
						textAnchor="middle"
						fill="#FFFFFF"
						fontFamily="Circe"
						fontWeight="400"
					>
						{t('fcol_binar_right_direction')}
					</text>
				</g>
			) : (
				<></>
			)}
		</g>
	)
}
