class LocalStorage {
	getItem(key) {
		const item = localStorage.getItem(key)

		if (item === null) return undefined

		if (item === 'null') return null
		if (item === 'undefined') return undefined

		try {
			return JSON.parse(item)
		} catch {}

		return item
	}

	setItem(key, value) {
		if (value === undefined) {
			localStorage.removeItem(key)
		} else {
			localStorage.setItem(key, JSON.stringify(value))
		}
	}
}

class MockStorage {
	getItem() {
		return null
	}
	setItem() {}
}
const persistentStorage = window?.localStorage
	? new LocalStorage()
	: new MockStorage()

export default persistentStorage
