import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useModalProps } from 'react-simple-modal-provider'
import { ethers } from 'ethers'
import {
	Address,
	useContractWrite,
	usePrepareContractWrite,
	useWaitForTransaction,
} from 'wagmi'
import { useContract } from 'src/hooks/useContract'
import { FCOL_ADDRESS } from 'src/constants'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'

export default function FColModalInviterForm() {
	const { t } = useTranslation()
	const { refetchReferrer } = useContract()
	const { close } = useModalProps('FColModalInviter')

	const [val, setVal] = useState('')
	const [errorMsg, setErrorMsg] = useState('')

	const { config } = usePrepareContractWrite({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'register',
		args: [val],
		enabled:
			ethers.utils.isAddress(val) && val !== ethers.constants.AddressZero,
	})
	const { data, write } = useContractWrite(config)
	const { isLoading } = useWaitForTransaction({
		hash: data?.hash,
		onSuccess() {
			refetchReferrer?.()
			toast.success('Successfully registered!')
			close()
		},
	})

	const addInviter = (event: any) => {
		event.preventDefault()
		if (!ethers.utils.isAddress(val)) {
			setErrorMsg(t('fcol_inviter_address_validation_message'))
		} else {
			write?.()
		}
	}

	const onChange = (value: string): void => {
		setVal(value)
		setErrorMsg('')
	}

	return (
		<form className="FColModalInviter-form" onSubmit={(e) => addInviter(e)}>
			<div>
				<input
					type="text"
					value={val}
					className="FColModalInviter-form_input"
					onChange={(e) => onChange(e.target.value)}
				/>
			</div>
			{errorMsg && <div style={{ color: 'red' }}>{errorMsg}</div>}
			<div className="FColModalInviter-form_btn">
				<button className="btn btn--matic" disabled={isLoading || !write}>
					{t('fcol_inviter_btn')}
				</button>
			</div>
		</form>
	)
}
