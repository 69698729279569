export const appName = 'OpenSmart'

export const socialsLinks = {
	discord: {
		ru: 'https://discord.gg/FtW7MvJD87',
		en: 'https://discord.gg/FtW7MvJD87',
	},
	telegram: {
		ru: 'https://t.me/grabcoinclub',
		en: 'https://t.me/grabcoinclub',
	},
	instagram: {
		ru: 'https://www.instagram.com/grabcoinclub/',
		en: 'https://www.instagram.com/grabcoinclub/',
	},
	facebook: {
		ru: 'https://www.facebook.com/Grab%D1%81oin%D1%81lub-106285751970621/',
		en: 'https://www.facebook.com/Grab%D1%81oin%D1%81lub-106285751970621/',
	},
	twitter: {
		ru: 'https://twitter.com/grabcoinclub?t=X3gCkEQLI8SwS1qe8cBHZA&s=09',
		en: 'https://twitter.com/grabcoinclub?t=X3gCkEQLI8SwS1qe8cBHZA&s=09',
	},
	medium: {
		ru: 'https://grabcoinclub.medium.com/',
		en: 'https://grabcoinclub.medium.com/',
	},
	youtube: {
		ru: 'https://www.youtube.com/channel/UCWQNwdAvgcyfMy40kdpRmmg',
		en: 'https://www.youtube.com/channel/UCWQNwdAvgcyfMy40kdpRmmg',
	},
}

export const PROGRAM_FCOL = 'FCOL'
export const PROGRAM_SCOL = 'SCOL'

export const FCOL_ADDRESS = process.env.REACT_APP_FCOL_ADDRESS || ''
export const DIVIDENDS_ADDRESS = process.env.REACT_APP_DIVIDENDS_ADDRESS || ''
export const FCOL_STATISTICS_ADDRESS =
	process.env.REACT_APP_FCOL_STATISTICS_ADDRESS || ''
export const SCOL_ADDRESS = process.env.REACT_APP_SCOL_ADDRESS || ''
export const SCOL_STATISTICS_ADDRESS =
	process.env.REACT_APP_SCOL_STATISTICS_ADDRESS || ''

export const FCOL_NFT_PRICE_LIST = [
	{
		gen: 1,
		price: 66,
	},
	{
		gen: 2,
		price: 99,
	},
	{
		gen: 3,
		price: 165,
	},
	{
		gen: 4,
		price: 330,
	},
	{
		gen: 5,
		price: 660,
	},
	{
		gen: 6,
		price: 990,
	},
	{
		gen: 7,
		price: 1650,
	},
	{
		gen: 8,
		price: 3300,
	},
	{
		gen: 9,
		price: 5940,
	},
	{
		gen: 10,
		price: 9900,
	},
	{
		gen: 11,
		price: 16500,
	},
	{
		gen: 12,
		price: 33000,
	},
	{
		gen: 13,
		price: 59400,
	},
	{
		gen: 14,
		price: 99000,
	},
	{
		gen: 15,
		price: 165000,
	},
]
