import React from 'react'
import { useConnect } from 'wagmi'
import { useTranslation } from 'react-i18next'
import { useModalProps } from 'react-simple-modal-provider'
import IconMetamask from 'src/icons/metamask.svg'
import IconWallet from 'src/icons/connectWallet.svg'
import { toast } from 'react-toastify'

export default function ModalLoginForm() {
	const { close } = useModalProps('ModalLogin')
	const { t } = useTranslation()
	const { connect, connectors } = useConnect({
		onError: (error) => {
			toast.error(error.message)
		},
	})

	return (
		<form className="ModalLogin-form">
			<div className="ModalLogin-form_btn">
				<button
					className="btn"
					onClick={(event) => {
						event.preventDefault()
						connect({ connector: connectors[0] })
						close()
					}}
				>
					<div className="ModalLogin-form_btn_svg">
						<img src={IconMetamask} alt="" />
					</div>
					<span className="ModalLogin-form_btn_text">
						{t('login_metamask')}
					</span>
				</button>
			</div>
			<div className="ModalLogin-form_btn">
				<button
					className="btn"
					onClick={(event) => {
						event.preventDefault()
						connect({ connector: connectors[1] })
						close()
					}}
				>
					<div className="ModalLogin-form_btn_svg">
						<img src={IconWallet} alt="" />
					</div>
					<span className="ModalLogin-form_btn_text">
						{t('login_wallet_connect')}
					</span>
				</button>
			</div>
		</form>
	)
}
