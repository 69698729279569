import { Route } from 'react-router-dom'
import FColBinarPage from './pages/FColBinarPage'
import FColNftMintPage from './pages/FColNftMintPage'
import FColNftMyMintPage from './pages/FColMyNftPage'
import FColHomePage from './pages/FColHomePage'
import FColLinePage from './pages/FColLinePage'
import FColSecurityPage from './pages/FColSecurityPage'
import FColTransactionsPage from './pages/FColTransactionsPage'

const routes = [
	<Route
		path={'/account/fcol'}
		element={<FColHomePage />}
		key="account_fcol"
	/>,
	<Route
		path={'/account/fcol/NFT/mint'}
		element={<FColNftMintPage />}
		key="account_fcol_nft_mint"
	/>,
	<Route
		path={'/account/fcol/NFT/my-mint'}
		element={<FColNftMyMintPage />}
		key="account_fcol_nft_my_mint"
	/>,
	<Route
		path={'/account/fcol/binar'}
		element={<FColBinarPage />}
		key="account_fcol_binar"
	/>,
	<Route
		path={'/account/fcol/line'}
		element={<FColLinePage />}
		key="account_fcol_line"
	/>,
	<Route
		path={'/account/fcol/transactions'}
		element={<FColTransactionsPage />}
		key="account_fcol_transactions"
	/>,
	<Route
		path={'/account/fcol/security'}
		element={<FColSecurityPage />}
		key="account_fcol_security"
	/>,
]

export default routes
