import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import {
	Address,
	useAccount,
	useContractReads,
	useContractWrite,
	usePrepareContractWrite,
	useWaitForTransaction,
} from 'wagmi'
import { ethers } from 'ethers'

import { useContract } from 'src/hooks/useContract'
import {
	FCOL_ADDRESS,
	PROGRAM_FCOL,
	PROGRAM_SCOL,
	SCOL_ADDRESS,
} from 'src/constants'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'
import './style.scss'

export default function SelectContract() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { address } = useAccount()
	const { setContractAddress } = useContract()

	const inviterCookie = Cookies.get('inviter')
	const inviter = inviterCookie ? inviterCookie : ethers.constants.AddressZero

	const { data: isRegistered } = useContractReads({
		contracts: [
			{
				address: FCOL_ADDRESS as Address,
				abi: OpenSmartAbi,
				functionName: 'isRegistered',
				args: [address],
			},
			{
				address: SCOL_ADDRESS as Address,
				abi: OpenSmartAbi,
				functionName: 'isRegistered',
				args: [address],
			},
		],
	})

	const { isError: isErrorFCOL, config: configFCOL } = usePrepareContractWrite({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'register',
		args: [inviter],
		enabled: inviter !== ethers.constants.AddressZero,
	})
	const { data: dataFCOL, write: writeFCOL } = useContractWrite(configFCOL)

	const { isError: isErrorSCOL, config: configSCOL } = usePrepareContractWrite({
		address: SCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'register',
		args: [inviter],
		enabled: inviter !== ethers.constants.AddressZero,
	})
	const { data: dataSCOL, write: writeSCOL } = useContractWrite(configSCOL)

	const { isLoading } = useWaitForTransaction({
		hash: dataFCOL?.hash ?? dataSCOL?.hash,
		onSuccess() {
			navigate('/account/' + PROGRAM_FCOL.toLowerCase())
		},
	})

	const loginFCOL = () => {
		setContractAddress(FCOL_ADDRESS as Address)
		if (
			isRegistered?.[0] ||
			inviter === ethers.constants.AddressZero ||
			isErrorFCOL ||
			!writeFCOL
		) {
			navigate('/account/' + PROGRAM_FCOL.toLowerCase())
		} else {
			writeFCOL()
		}
	}

	const loginSCOL = () => {
		setContractAddress(SCOL_ADDRESS as Address)
		if (
			isRegistered?.[1] ||
			inviter === ethers.constants.AddressZero ||
			isErrorSCOL ||
			!writeSCOL
		) {
			navigate('/account/' + PROGRAM_SCOL.toLowerCase())
		} else {
			writeSCOL()
		}
	}

	return (
		<div className="SelectContract">
			<div className="SelectContract-account">
				<p>{t('select_contract_account')}</p>
				<strong>{address}</strong>
			</div>
			<div className="SelectContract_wrap">
				<div className="SelectContractItem">
					<div className="SelectContractItem-info">
						<div className="SelectContractItem-title">
							{t('select_contract_program_1')}
						</div>
						<button
							className="SelectContractItem-link"
							disabled={isLoading}
							onClick={() => loginFCOL()}
						>
							{t('select_contract_login')}
							{isLoading && '...'}
						</button>
					</div>
					<div className="SelectContractItem-image">
						<img src={process.env.PUBLIC_URL + '/img/matic.png'} alt="" />
					</div>
				</div>
				<div className="SelectContractItem">
					<div className="SelectContractItem-info">
						<div className="SelectContractItem-title">
							{t('select_contract_program_2')}
						</div>
						<button
							className="SelectContractItem-link"
							disabled={isLoading || true}
							onClick={() => loginSCOL()}
						>
							{t('select_contract_soon')}
							{isLoading && '...'}
						</button>
					</div>
					<div className="SelectContractItem-image">
						<img src={process.env.PUBLIC_URL + '/img/matic.png'} alt="" />
					</div>
				</div>
			</div>
		</div>
	)
}
