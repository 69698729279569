import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useModal } from 'react-simple-modal-provider'
import { ethers } from 'ethers'

import {
	Address,
	useContractReads,
	useContractWrite,
	usePrepareContractWrite,
	useWaitForTransaction,
} from 'wagmi'
import { useContract } from 'src/hooks/useContract'
import { DIVIDENDS_ADDRESS, FCOL_ADDRESS } from 'src/constants'
import GrabCoinClubAbi from 'src/abis/GrabCoinClubAbi.json'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'
import DividendsAbi from 'src/abis/DividendsAbi.json'
import './style.scss'

interface Props {
	id: number
}

export default function FColNft(props: Props) {
	const { t } = useTranslation()
	const { nftCollection, stakedTokenId } = useContract()

	const { open: openFColModalUpgrade, close: closeFColModalUpgrade } =
		useModal('FColModalUpgrade')

	const { open: openNftMyMftWithdrawModal, close: closeNftMyMftWithdrawModal } =
		useModal('NftMyMftWithdrawModal')

	const [image, setImage] = useState<string>(
		process.env.PUBLIC_URL + '/img/c3-1.jpg'
	)

	const { data } = useContractReads({
		contracts: [
			{
				address: nftCollection,
				abi: GrabCoinClubAbi,
				functionName: 'tokenURI',
				args: [props.id],
			},
			{
				address: nftCollection,
				abi: GrabCoinClubAbi,
				functionName: 'seriesOf',
				args: [props.id],
			},
			{
				address: DIVIDENDS_ADDRESS as Address,
				abi: DividendsAbi,
				functionName: 'calcDividends',
				args: [props.id],
			},
		],
		select: (data: any) => data as Array<any>,
	})

	useEffect(() => {
		if (data?.[0]) {
			fetch(data?.[0])
				.then((response) => response.json())
				.then((data) => {
					setImage(data.image)
				})
				.catch((e) => console.log(e))
		}
	}, [data])

	const { isError: stakeIsError, config: stakeConfig } =
		usePrepareContractWrite({
			address: FCOL_ADDRESS as Address,
			abi: OpenSmartAbi,
			functionName: 'stake',
			args: [props.id],
		})
	const { data: stakeData, write: stakeWrite } = useContractWrite(stakeConfig)
	const { isLoading: stakeIsLoading } = useWaitForTransaction({
		hash: stakeData?.hash,
		onSuccess() {
			toast.success('Successfully staked')
			window.location.reload()
		},
	})

	const { isError: unstakeIsError, config: unstakeConfig } =
		usePrepareContractWrite({
			address: FCOL_ADDRESS as Address,
			abi: OpenSmartAbi,
			functionName: 'unstake',
			args: [props.id],
			enabled: stakedTokenId === props.id,
		})
	const { data: unstakeData, write: unstakeWrite } =
		useContractWrite(unstakeConfig)
	const { isLoading: unstakeIsLoading } = useWaitForTransaction({
		hash: unstakeData?.hash,
		onSuccess() {
			toast.success('Successfully unstaked')
			window.location.reload()
		},
	})

	const { isError: grabIsError, config: grabConfig } = usePrepareContractWrite({
		address: DIVIDENDS_ADDRESS as Address,
		abi: DividendsAbi,
		functionName: 'grabDividends',
		args: [props.id],
	})
	const { data: grabData, write: grabWrite } = useContractWrite(grabConfig)
	const { isLoading: grabIsLoading } = useWaitForTransaction({
		hash: grabData?.hash,
		onSuccess() {
			toast.success('Successfully grabbed')
			window.location.reload()
		},
	})

	const handleWithdrawClick = () => {
		openNftMyMftWithdrawModal({
			id: props.id,
			close: closeNftMyMftWithdrawModal,
		})
	}

	return (
		<div className="FColNftMyMint-cell">
			<div className="FColNftMyMint-card">
				<div
					className={
						!stakeIsError || !unstakeIsError || !grabIsError
							? 'FColNftMyMint-card-active'
							: 'FColNftMyMint-card-inactive'
					}
				>
					<img src={image} alt={String(props.id)} />
					<div className="FColNftMyMint-series">
						<div>
							{data
								? Number(Number(data?.[1]) + 1) + t('fcol_nft_mint_btn_series')
								: '-'}
						</div>
					</div>
					{!stakeIsError ? (
						<div className="FColNftMyMint-btn">
							<button
								className="btn btn--matic"
								disabled={stakeIsLoading || !stakeWrite || stakeIsError}
								onClick={() => stakeWrite?.()}
							>
								{t('fcol_nft_mint_btn_staking')}
							</button>
						</div>
					) : (
						<div className="FColNftMyMint-btn">
							<button
								className="btn btn--green"
								disabled={props.id !== stakedTokenId}
								onClick={() =>
									openFColModalUpgrade({
										tokenId: props.id,
										close: closeFColModalUpgrade,
									})
								}
							>
								{t('fcol_nft_mint_btn_upgrade')}
							</button>
						</div>
					)}
					<div className="FColNftMyMint-btn">
						<button
							className="btn btn--transparent"
							disabled={unstakeIsLoading || !unstakeWrite || unstakeIsError}
							onClick={() => handleWithdrawClick()}
						>
							{t('fcol_nft_mint_btn_unstaking')}
						</button>
					</div>
					<div className="FColNftMyMint-label">
						<button
							className="label FColNftMyMint-label--transparent"
							disabled={grabIsError}
						>
							{`${ethers.utils.formatEther(data?.[2] || 0)} GC`}
						</button>
						<button
							className="FColNftMyMint-label--pink"
							disabled={grabIsLoading || !grabWrite || grabIsError}
							onClick={() => grabWrite?.()}
						>
							{t('fcol_nft_mint_btn_grab_bonuses')}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
