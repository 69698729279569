import Page from 'src/components/Page'
import { useTranslation } from 'react-i18next'
import Layout from '../components/Layout/Layout'
import FColStats from '../components/Stats'
import FColTitle from '../components/Title'

export default function FColHomePage() {
	const { t } = useTranslation()

	return (
		<Page title={t('fcol_home_page_title')}>
			<Layout>
				<div className="FColHomePage">
					<FColTitle>{t('fcol_home_page_title')}</FColTitle>
					<FColStats />
				</div>
			</Layout>
		</Page>
	)
}
