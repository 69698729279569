import React from 'react'
import { useModal } from 'react-simple-modal-provider'

import { BigNumber, ethers } from 'ethers'
import { Address, useContractRead, useEnsAvatar, useEnsName } from 'wagmi'

import { useContract } from 'src/hooks/useContract'
import { FCOL_ADDRESS } from 'src/constants'
import GrabCoinClubAbi from 'src/abis/GrabCoinClubAbi.json'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'
import getShortWallet from 'src/utils/getShortWallet'

interface Props {
	address: Address
	x: number
	y: number
	width: number
	height: number
}

export default function FColBinaryStructureNode(props: Props) {
	const x = props.x
	const y = props.y
	const width = props.width
	const height = props.height

	const { nftCollection } = useContract()
	const { open: openFColModalNode } = useModal('FColModalNode')

	const { data: ensAvatar } = useEnsAvatar({ address: props.address })
	const { data: ensName } = useEnsName({ address: props.address })

	const { data: tokenId } = useContractRead({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'stakers',
		args: [props.address],
		select: (data: any) => (data[0] ? data[1] : -1),
	})

	const { data: gen } = useContractRead({
		address: nftCollection,
		abi: GrabCoinClubAbi,
		functionName: 'seriesOf',
		args: [tokenId],
		enabled: tokenId >= 0,
		select: (data: any) => data.toNumber(),
	})

	const { data: price } = useContractRead({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'tokenPrices',
		args: [ethers.constants.AddressZero, gen],
	})

	return (
		<g onClick={() => openFColModalNode({ address: props.address })}>
			<rect
				x={x}
				y={y}
				width={width}
				height={height}
				style={{
					fill: '#141B22',
					strokeWidth: '1',
					stroke: '#8247E5',
				}}
			/>

			<g
				transform={'translate(' + (x + width / 2 - 20) + ', ' + (y + 25) + ')'}
			>
				<svg
					width="44"
					height="45"
					viewBox="0 0 44 45"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M21.7759 0.860779C9.89574 0.860779 0.253906 10.5502 0.253906 22.4889C0.253906 34.4276 9.89574 44.117 21.7759 44.117C33.656 44.117 43.2978 34.4276 43.2978 22.4889C43.2978 10.5502 33.656 0.860779 21.7759 0.860779ZM21.7759 7.34921C25.3485 7.34921 28.2324 10.2474 28.2324 13.8377C28.2324 17.4279 25.3485 20.3261 21.7759 20.3261C18.2032 20.3261 15.3193 17.4279 15.3193 13.8377C15.3193 10.2474 18.2032 7.34921 21.7759 7.34921ZM21.7759 38.0611C16.3954 38.0611 11.639 35.2927 8.86268 31.0969C8.92725 26.7929 17.4715 24.4354 21.7759 24.4354C26.0587 24.4354 34.6245 26.7929 34.689 31.0969C31.9127 35.2927 27.1563 38.0611 21.7759 38.0611Z"
						fill="#8247E5"
					/>
				</svg>
			</g>
			<g transform={'translate(' + (x + width - 30) + ', ' + (y + 20) + ')'}>
				<svg
					width="23"
					height="15"
					viewBox="0 0 23 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					{ensAvatar ? (
						<image href={ensAvatar} height={15} width={23} />
					) : (
						<>
							<path
								d="M11.636 4.63721C10.0169 4.63721 8.70117 5.95945 8.70117 7.58649C8.70117 9.21353 10.0169 10.5358 11.636 10.5358C13.255 10.5358 14.5708 9.21353 14.5708 7.58649C14.5708 5.95945 13.255 4.63721 11.636 4.63721Z"
								fill="#616182"
							/>
							<path
								d="M11.636 0.213257C6.74463 0.213257 2.56742 3.27066 0.875 7.58649C2.56742 11.9023 6.74463 14.9597 11.636 14.9597C16.5322 14.9597 20.7046 11.9023 22.397 7.58649C20.7046 3.27066 16.5322 0.213257 11.636 0.213257ZM11.636 12.5019C8.93597 12.5019 6.74463 10.2998 6.74463 7.58644C6.74463 4.87309 8.93597 2.67098 11.636 2.67098C14.336 2.67098 16.5274 4.87314 16.5274 7.58649C16.5274 10.2998 14.336 12.5019 11.636 12.5019Z"
								fill="#616182"
							/>
						</>
					)}
				</svg>
			</g>
			<text
				x={x + width / 2}
				y={y + 100}
				fontSize="16px"
				textAnchor="middle"
				fill="#FFFFFF"
				fontFamily="Circe"
				fontWeight="400"
			>
				{ensName ? `${ensName}` : ``}
			</text>
			<text
				x={x + width / 2}
				y={y + 130}
				fontSize="14px"
				textAnchor="middle"
				fill="#8247E5"
				fontFamily="Circe"
				fontWeight="400"
			>
				{getShortWallet(props.address)}
			</text>
			{price ? (
				<text
					x={x + width / 2}
					y={y + 165}
					fontSize="16px"
					textAnchor="middle"
					fill="#FFFFFF"
					fontFamily="Circe"
					fontWeight="400"
				>
					{`Gen ${gen + 1} - ${ethers.utils.formatEther(
						price as BigNumber
					)} MATIC`}
				</text>
			) : (
				<></>
			)}
			<text
				x={x + width / 2}
				y={y + 200}
				fontSize="16px"
				textAnchor="middle"
				fill="#808080"
				fontFamily="Circe"
				fontWeight="400"
			>
				{``}
			</text>
		</g>
	)
}
