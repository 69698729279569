import './Header.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useAccount, useDisconnect } from 'wagmi'
import { appName } from 'src/constants'
import Socials from '../Socials/Socials'
import LangSwitcher from '../LangSwitcher/LangSwitcher'
import { useModal } from 'react-simple-modal-provider'
import {
	isShowMobileSidebar,
	toggleMobileSidebar,
} from 'src/store/reducers/mobileSidebar'
import getShortWallet from 'src/utils/getShortWallet'

export default function Header() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { address } = useAccount()
	const { disconnect } = useDisconnect({
		onSuccess: () => {
			navigate('/')
		},
	})

	const less1200 = useMediaQuery({ maxWidth: 1200 })
	const sidebarBtn = useSelector(isShowMobileSidebar)
	const dispatch = useDispatch()
	const mobileSidebarAction = () => {
		dispatch(toggleMobileSidebar())
	}

	const location = useLocation()
	const showSidebarBtn = location.pathname.includes('/account/')

	const { open: openModalLogin, close: closeModalLogin } =
		useModal('ModalLogin')

	const openConnectModal = () => {
		openModalLogin({
			close: closeModalLogin,
		})
	}

	return (
		<header className="Header">
			<div className="container">
				<div className="Header_wrap">
					{less1200 && showSidebarBtn && (
						<button
							className={
								'Header-sidebarBtn' +
								(sidebarBtn ? ' Header-sidebarBtn--active' : '')
							}
							onClick={mobileSidebarAction}
						>
							<span></span>
							<span></span>
							<span></span>
						</button>
					)}
					<div className="Header-logo">
						<Link to="/">
							<img
								src={process.env.PUBLIC_URL + '/img/logo.png'}
								alt={appName}
							/>
						</Link>
					</div>
					<Socials />
					<div className="Header-auth">
						{address ? (
							<>
								<Link className="btn" to="/account">
									{getShortWallet(address)}
								</Link>
								<button className="btn" onClick={() => disconnect()}>
									{t('btn_logout')}
								</button>
							</>
						) : (
							<button className="btn" onClick={() => openConnectModal()}>
								{t('btn_connect')}
							</button>
						)}
					</div>
					<LangSwitcher />
				</div>
			</div>
		</header>
	)
}
