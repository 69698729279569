import React from 'react'
import { socialsLinks } from '../../constants'
import SocialsIcons from './SocialsIcons'
import { useTranslation } from 'react-i18next'

function Socials() {
	const { i18n } = useTranslation()
	const getSocialByLang = (obj, desc) => {
		let arr = desc.split('.')
		while (arr.length && (obj = obj[arr.shift()]));
		return obj
	}

	return (
		<nav className="socials">
			<a
				href={getSocialByLang(socialsLinks, 'discord.' + i18n.language)}
				target="_blank"
				rel="noreferrer"
			>
				<SocialsIcons code="discord" />
			</a>
			<a
				href={getSocialByLang(socialsLinks, 'twitter.' + i18n.language)}
				target="_blank"
				rel="noreferrer"
			>
				<SocialsIcons code="twitter" />
			</a>
			<a
				href={getSocialByLang(socialsLinks, 'medium.' + i18n.language)}
				target="_blank"
				rel="noreferrer"
			>
				<SocialsIcons code="medium" />
			</a>
			<a
				href={getSocialByLang(socialsLinks, 'telegram.' + i18n.language)}
				target="_blank"
				rel="noreferrer"
			>
				<SocialsIcons code="telegram" />
			</a>
			<a
				href={getSocialByLang(socialsLinks, 'facebook.' + i18n.language)}
				target="_blank"
				rel="noreferrer"
			>
				<SocialsIcons code="facebook" />
			</a>
			<a
				href={getSocialByLang(socialsLinks, 'instagram.' + i18n.language)}
				target="_blank"
				rel="noreferrer"
			>
				<SocialsIcons code="instagram" />
			</a>
			<a
				href={getSocialByLang(socialsLinks, 'youtube.' + i18n.language)}
				target="_blank"
				rel="noreferrer"
			>
				<SocialsIcons code="youtube" />
			</a>
		</nav>
	)
}

export default Socials
