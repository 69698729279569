import { Trans, useTranslation } from 'react-i18next'
import './style.scss'

export default function FColActiveBonus() {
	const { t } = useTranslation()

	return (
		<div className="FColActiveBonus">
			<div className="FColActiveBonus-info">
				<div className="FColActiveBonus-info_title">
					{t('fcol_active_bonus_title')}
				</div>
				<div className="FColActiveBonus-info_content">
					<Trans i18nKey="fcol_active_bonus_text" />
				</div>
			</div>
			<div className="FColActiveBonus-content">
				<div className="FColActiveBonus_table">
					<table>
						<thead>
							<tr>
								<th>NFT</th>
								<th>MATIC</th>
								<th>1 Line</th>
								<th>2 Line</th>
								<th>3 Line</th>
								<th>4 Line</th>
								<th>5 Line</th>
								<th>6 Line</th>
								<th>7 Line</th>
								<th>8 Line</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>GEN 1</th>
								<td>66</td>
								<td>10%</td>
								<td>8%</td>
								<td>1%</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>GEN 2</th>
								<td>99</td>
								<td>10%</td>
								<td>8%</td>
								<td>2%</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>GEN 3</th>
								<td>165</td>
								<td>10%</td>
								<td>8%</td>
								<td>3%</td>
								<td>0.5%</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>GEN 4</th>
								<td>330</td>
								<td>10%</td>
								<td>8%</td>
								<td>4%</td>
								<td>1%</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>GEN 5</th>
								<td>660</td>
								<td>10%</td>
								<td>8%</td>
								<td>5%</td>
								<td>2%</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>GEN 6</th>
								<td>990</td>
								<td>10%</td>
								<td>8%</td>
								<td>6%</td>
								<td>3%</td>
								<td>0.5%</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>GEN 7</th>
								<td>1 650</td>
								<td>10%</td>
								<td>8%</td>
								<td>6%</td>
								<td>5%</td>
								<td>1%</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>GEN 8</th>
								<td>3 300</td>
								<td>10%</td>
								<td>8%</td>
								<td>6%</td>
								<td>5%</td>
								<td>2%</td>
								<td>0.5%</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>GEN 9</th>
								<td>5 940</td>
								<td>10%</td>
								<td>8%</td>
								<td>6%</td>
								<td>5%</td>
								<td>3%</td>
								<td>0.5%</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>GEN 10</th>
								<td>9 900</td>
								<td>10%</td>
								<td>8%</td>
								<td>6%</td>
								<td>5%</td>
								<td>4%</td>
								<td>1%</td>
								<td>0.5%</td>
								<td></td>
							</tr>
							<tr>
								<th>GEN 11</th>
								<td>16 500</td>
								<td>10%</td>
								<td>8%</td>
								<td>6%</td>
								<td>5%</td>
								<td>4%</td>
								<td>2%</td>
								<td>0.5%</td>
								<td></td>
							</tr>
							<tr>
								<th>GEN 12</th>
								<td>33 000</td>
								<td>10%</td>
								<td>8%</td>
								<td>6%</td>
								<td>5%</td>
								<td>4%</td>
								<td>3%</td>
								<td>1%</td>
								<td>0.5%</td>
							</tr>
							<tr>
								<th>GEN 13</th>
								<td>59 400</td>
								<td>10%</td>
								<td>8%</td>
								<td>6%</td>
								<td>5%</td>
								<td>4%</td>
								<td>3%</td>
								<td>1%</td>
								<td>1%</td>
							</tr>
							<tr>
								<th>GEN 14</th>
								<td>99 000</td>
								<td>10%</td>
								<td>8%</td>
								<td>6%</td>
								<td>5%</td>
								<td>4%</td>
								<td>3%</td>
								<td>1%</td>
								<td>2%</td>
							</tr>
							<tr>
								<th>GEN 15</th>
								<td>165 000</td>
								<td>10%</td>
								<td>8%</td>
								<td>6%</td>
								<td>5%</td>
								<td>4%</td>
								<td>3%</td>
								<td>1%</td>
								<td>3%</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}
