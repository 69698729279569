import React, { useState, useRef, useEffect } from 'react'
import { Value, ReactSVGPanZoom, Tool, TOOL_NONE } from 'react-svg-pan-zoom'
import FColLinearStructureCanvas from './canvas'
import './style.scss'

export default function FColLinearStructure() {
	const [width, setWidth] = useState(1)
	const [height] = useState(1000)
	const containerRef = useRef<any>(null)
	const Viewer = useRef(null)
	const [tool, setTool] = useState<Tool>(TOOL_NONE)
	const [value, setValue] = useState<Value>({
		version: 2,
		SVGHeight: width,
		SVGWidth: height,
		a: 1,
		b: 0,
		c: 0,
		d: 1,
		e: 0,
		endX: null,
		endY: null,
		f: 180,
		focus: false,
		miniatureOpen: true,
		mode: 'idle',
		startX: null,
		startY: null,
		viewerHeight: 400,
		viewerWidth: 400,
	})

	useEffect(() => {
		if (containerRef.current) {
			setWidth(containerRef.current.offsetWidth)
		}

		const handleResize = () => {
			if (containerRef.current) {
				setWidth(containerRef.current.offsetWidth)
			}
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const handleChangeValue = (v: Value) => {
		setValue(v)
	}

	return (
		<div className="FColLineStructure">
			<div ref={containerRef}>
				<ReactSVGPanZoom
					ref={Viewer}
					width={width}
					height={height}
					background="#040B11"
					SVGBackground="#040B11"
					tool={tool}
					onChangeTool={setTool}
					detectWheel={false}
					value={value}
					onChangeValue={handleChangeValue}
					scaleFactorMin={0.5}
					scaleFactorMax={10}
					detectAutoPan={false}
					style={{ gridColumn: 2, gridRow: 2 }}
					toolbarProps={{
						position: 'left',
						SVGAlignX: 'center',
						SVGAlignY: 'center',
					}}
					miniatureProps={{
						position: 'none',
						background: '#808080',
						width: 100,
						height: 100,
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						id="svg_root"
						width={width}
						height={height}
						fill={'#040B11'}
					>
						<g>
							<FColLinearStructureCanvas width={width} height={height} />
						</g>
					</svg>
				</ReactSVGPanZoom>
			</div>
		</div>
	)
}
