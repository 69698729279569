import Page from 'src/components/Page'
import { useTranslation } from 'react-i18next'
import FColActiveBonus from '../components/ActiveBonus'
import Layout from '../components/Layout/Layout'
import FColLineStructure from '../components/LinearStructure'
import FColPassiveBonus from '../components/PassiveBonus'
import FColTitle from '../components/Title'

export default function FColLinePage() {
	const { t } = useTranslation()

	return (
		<Page title={t('fcol_line_page_title')}>
			<Layout>
				<div className="FColLinePage">
					<FColTitle>{t('fcol_line_page_title')}</FColTitle>
					<FColPassiveBonus />
					<FColActiveBonus />
					<FColLineStructure />
				</div>
			</Layout>
		</Page>
	)
}
