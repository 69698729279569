import { Route } from 'react-router-dom'
import AccountPage from './pages/AccountPage'
import FColRouter from './features/FCOL/router'

const routes = [
	<Route path={'/account'} element={<AccountPage />} key="account" />,
	...FColRouter,
]

export default routes
