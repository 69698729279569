import React, { Suspense } from 'react'
import { ToastContainer } from 'react-toastify'
import { HelmetProvider } from 'react-helmet-async'
import { ModalProvider } from 'react-simple-modal-provider'
import ModalLogin from 'src/components/ModalLogin'
import FColModalUpgrade from 'src/features/Account/features/FCOL/components/ModalUpgrade'
import FColModalInviter from 'src/features/Account/features/FCOL/components/ModalInviter'
import FColModalNode from 'src/features/Account/features/FCOL/components/ModalNode'
import NftMyNftWithdrawModal from 'src/features/Account/features/FCOL/components/NftMyNftWithdrawModal'
import { Provider as StoreProvider } from 'react-redux'
import { store } from './store'
import { WagmiConfig, client } from 'src/wagmi'
import { ContractProvider } from './context/ContractContext'

export default function Providers({ children }) {
	return (
		<Suspense fallback={null}>
			<WagmiConfig client={client}>
				<ContractProvider>
					<StoreProvider store={store}>
						<HelmetProvider>
							<ModalProvider
								value={[
									ModalLogin,
									FColModalUpgrade,
									FColModalInviter,
									FColModalNode,
									NftMyNftWithdrawModal,
								]}
							>
								{children}
							</ModalProvider>
						</HelmetProvider>

						<ToastContainer
							position="top-right"
							theme="dark"
							autoClose={3500}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							//pauseOnFocusLoss
							draggable
							//pauseOnHover
							style={{ zIndex: 10000 }}
						/>
					</StoreProvider>
				</ContractProvider>
			</WagmiConfig>
		</Suspense>
	)
}
