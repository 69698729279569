import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import {
	Address,
	useAccount,
	useContractRead,
	useContractWrite,
	usePrepareContractWrite,
	useWaitForTransaction,
} from 'wagmi'
import { ethers } from 'ethers'
import { useContract } from 'src/hooks/useContract'
import { FCOL_ADDRESS, FCOL_NFT_PRICE_LIST } from 'src/constants'
import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'
import './style.scss'

export default function FColNftMint() {
	const { t } = useTranslation()
	const { address } = useAccount()
	const { refetchTokens } = useContract()

	const [gen, setGen] = useState<number>(-1)

	const { data: mintLimit } = useContractRead({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'mintLimit',
		select: (data: any) => data,
	})

	const { data: mintsOf, refetch: refetchMintsOf } = useContractRead({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'mintsOf',
		args: [address, gen],
		select: (data: any) => data,
		enabled: Boolean(address) && gen >= 0,
	})

	const { data: price } = useContractRead({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'tokenPrices',
		args: [ethers.constants.AddressZero, gen],
		select: (data: any) => data,
		enabled: gen >= 0,
	})

	const { config } = usePrepareContractWrite({
		address: FCOL_ADDRESS as Address,
		abi: OpenSmartAbi,
		functionName: 'mint',
		args: [
			gen,
			1,
			ethers.constants.AddressZero,
			price,
			ethers.constants.AddressZero,
		],
		overrides: {
			value: price,
		},
		enabled: Boolean(price) && gen >= 0,
		onError: (err: any) => {
			toast.error(err.reason)
		},
	})
	const { data, write } = useContractWrite(config)
	const { isLoading } = useWaitForTransaction({
		hash: data?.hash,
		onSuccess: () => {
			toast.success('Successfully minted')
			void refetchTokens?.()
			void refetchMintsOf()
		},
	})

	const mint = (event: any) => {
		event.preventDefault()
		write?.()
	}

	return (
		<div className="FColNftMint">
			<div className="FColNftMint-form-row">
				<form className="FColNftMint-form" onSubmit={(e) => mint(e)}>
					<div className="FColNftMint-form-row">
						<select
							className="FColNftMint-form_select"
							onChange={(event) => {
								setGen(Number(event.target.value))
							}}
						>
							<option>{t('fcol_nft_mint_select')}</option>
							{FCOL_NFT_PRICE_LIST.map((item, index) => {
								return (
									<option value={item.gen - 1} key={index}>
										Gen {item.gen}
									</option>
								)
							})}
						</select>
					</div>
					<div className="FColNftMint-form-row">
						<div className="FColNftMint-form-cell">
							<span>{t('fcol_nft_mint_price')}</span>
						</div>
						<div className="FColNftMint-form-cell">
							<div className="FColNftMint-form-cell-value">
								<span>{price ? ethers.utils.formatEther(price) : ''}</span>
							</div>
						</div>
					</div>
					<div className="FColNftMint-form-row">
						<div className="FColNftMint-form-cell">
							<span>{t('fcol_nft_mint_limit')}</span>
						</div>
						<div className="FColNftMint-form-cell">
							<div className="FColNftMint-form-cell-value">
								<span>{mintLimit?.toNumber() - mintsOf?.toNumber() || ''}</span>
							</div>
						</div>
					</div>
					<div className="FColNftMint-form-row">
						<div className="FColNftMint-form-btn">
							<button className="btn btn--matic" disabled={!write || isLoading}>
								{t('fcol_nft_mint_btn')}
								{isLoading ? '...' : ''}
							</button>
						</div>
					</div>
				</form>
				<div className="FColNftMint-avatar">
					<img src={process.env.PUBLIC_URL + '/img/c3-1.jpg'} alt="" />
				</div>
			</div>
		</div>
	)
}
