import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { polygon, polygonMumbai } from '@wagmi/chains'

import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'

import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

const { chains, provider, webSocketProvider } = configureChains(
	[process.env.REACT_APP_NODE_ENV === 'development' ? polygonMumbai : polygon],
	[
		infuraProvider({
			apiKey:
				process.env.REACT_APP_INFURA_API_KEY ||
				'cc89f492cadd4cec82ec565ae0931e02',
		}),
		publicProvider(),
	]
)

const client = createClient({
	autoConnect: true,
	connectors: [
		new MetaMaskConnector({ chains }),
		new WalletConnectConnector({
			chains,
			options: {
				projectId: '392b4261d355be99b83c80c93f57d1c7',
			},
		}),
		new InjectedConnector({
			chains,
			options: {
				name: 'Injected',
				shimDisconnect: true,
			},
		}),
	],
	provider,
	webSocketProvider,
})

export { client, chains, WagmiConfig }
