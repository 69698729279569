import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ethers } from 'ethers'
import {
	Address,
	useAccount,
	useContractReads,
	useContractWrite,
	usePrepareContractWrite,
	useWaitForTransaction,
} from 'wagmi'

import { FCOL_ADDRESS, FCOL_STATISTICS_ADDRESS } from 'src/constants'

import OpenSmartAbi from 'src/abis/OpenSmartAbi.json'
import OpenSmartStatisticsAbi from 'src/abis/OpenSmartStatistics.json'

import './style.scss'
import { toast } from 'react-toastify'

export default function FColStats() {
	const { t } = useTranslation()

	const { address } = useAccount()

	const { data, refetch } = useContractReads({
		contracts: [
			{
				address: FCOL_STATISTICS_ADDRESS as Address,
				abi: OpenSmartStatisticsAbi,
				functionName: 'calcPersonalRefereesStatistics',
				args: [address],
			},
			{
				address: FCOL_STATISTICS_ADDRESS as Address,
				abi: OpenSmartStatisticsAbi,
				functionName: 'calcRefRewardStatistics',
				args: [address],
			},
			{
				address: FCOL_STATISTICS_ADDRESS as Address,
				abi: OpenSmartStatisticsAbi,
				functionName: 'calcBinaryRewardStatistics',
				args: [address],
			},
			{
				address: FCOL_ADDRESS as Address,
				abi: OpenSmartAbi,
				functionName: 'calcBinaryReward',
				args: [address],
			},
			{
				address: FCOL_ADDRESS as Address,
				abi: OpenSmartAbi,
				functionName: 'calcLinearReward',
				args: [address],
			},
		],
		select: (data: any) => data,
	})

	const binaryReward = ethers.utils.formatEther(data?.[3]?.[0] ?? 0)
	const linearReward = ethers.utils.formatEther(data?.[4] ?? 0)

	const { isError: binaryIsError, config: binaryConfig } =
		usePrepareContractWrite({
			address: FCOL_ADDRESS as Address,
			abi: OpenSmartAbi,
			functionName: 'claimBinaryReward',
			enabled: Number(binaryReward) > 0,
		})
	const { data: binaryData, write: binaryWrite } =
		useContractWrite(binaryConfig)
	const { isLoading: binaryIsLoading } = useWaitForTransaction({
		hash: binaryData?.hash,
		onSuccess() {
			toast.success('Successfully claimed')
			void refetch()
		},
	})

	const { isError: linearIsError, config: linearConfig } =
		usePrepareContractWrite({
			address: FCOL_ADDRESS as Address,
			abi: OpenSmartAbi,
			functionName: 'claimRefReward',
			enabled: Number(linearReward) > 0,
		})
	const { data: linearData, write: linearWrite } =
		useContractWrite(linearConfig)
	const { isLoading: linearIsLoading } = useWaitForTransaction({
		hash: linearData?.hash,
		onSuccess() {
			toast.success('Successfully claimed')
			void refetch()
		},
	})

	return (
		<>
			<div className="FColStats">
				<div className="FColStats_table">
					<table>
						<thead>
							<tr>
								<th>{t('fcol_stats_title')}</th>
								<th>{t('fcol_stats_day')}</th>
								<th>{t('fcol_stats_week')}</th>
								<th>{t('fcol_stats_month')}</th>
								<th>{t('fcol_stats_year')}</th>
								<th>{t('fcol_stats_total')}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{t('fcol_stats_new_partner')}</td>
								<td>{data?.[0]?.[0][0]?.toString() ?? 0}</td>
								<td>{data?.[0]?.[0][1]?.toString() ?? 0}</td>
								<td>{data?.[0]?.[0][2]?.toString() ?? 0}</td>
								<td>{data?.[0]?.[0][3]?.toString() ?? 0}</td>
								<td>{data?.[0]?.[0][4]?.toString() ?? 0}</td>
							</tr>
							<tr>
								<td>{t('fcol_stats_bought_nft')}</td>
								<td>{data?.[0]?.[1][0]?.toString() ?? 0}</td>
								<td>{data?.[0]?.[1][1]?.toString() ?? 0}</td>
								<td>{data?.[0]?.[1][2]?.toString() ?? 0}</td>
								<td>{data?.[0]?.[1][3]?.toString() ?? 0}</td>
								<td>{data?.[0]?.[1][4]?.toString() ?? 0}</td>
							</tr>
							<tr>
								<td>{t('fcol_stats_binar_bonus')}</td>
								<td>{ethers.utils.formatEther(data?.[2]?.[0] ?? 0)}</td>
								<td>{ethers.utils.formatEther(data?.[2]?.[1] ?? 0)}</td>
								<td>{ethers.utils.formatEther(data?.[2]?.[2] ?? 0)}</td>
								<td>{ethers.utils.formatEther(data?.[2]?.[3] ?? 0)}</td>
								<td>{ethers.utils.formatEther(data?.[2]?.[4] ?? 0)}</td>
							</tr>
							<tr>
								<td>{t('fcol_stats_line_bonus')}</td>
								<td>{ethers.utils.formatEther(data?.[1]?.[0] ?? 0)}</td>
								<td>{ethers.utils.formatEther(data?.[1]?.[1] ?? 0)}</td>
								<td>{ethers.utils.formatEther(data?.[1]?.[2] ?? 0)}</td>
								<td>{ethers.utils.formatEther(data?.[1]?.[3] ?? 0)}</td>
								<td>{ethers.utils.formatEther(data?.[1]?.[4] ?? 0)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className="FColStats">
				<div className="FColStats_claim">
					<div className="FColStats_claim_label">
						{t('fcol_cabinet_binary_bonus')}
					</div>
					<div className="FColStats_claim_value">{binaryReward}</div>
					<div className="FColStats_claim_button">
						<button
							className="btn btn--matic"
							disabled={binaryIsLoading || !binaryWrite || binaryIsError}
							onClick={() => binaryWrite?.()}
						>
							{t('fcol_binar_structure_claim')}
						</button>
					</div>
				</div>
				<div className="FColStats_claim">
					<div className="FColStats_claim_label">
						{t('fcol_cabinet_linear_bonus')}
					</div>
					<div className="FColStats_claim_value">{linearReward}</div>
					<div className="FColStats_claim_button">
						<button
							className="btn btn--matic"
							disabled={linearIsLoading || !linearWrite || linearIsError}
							onClick={() => linearWrite?.()}
						>
							{t('fcol_binar_structure_claim')}
						</button>
					</div>
				</div>
			</div>
			<div className="FColStats_claim_content">
				<p>
					<Trans i18nKey="fcol_cabinet_1" />
					<a
						href="https://opensea.io/collection/grabcoinclub"
						target="_blank"
						rel="noreferrer"
					>
						{t('fcol_cabinet_2')}
					</a>
				</p>
				<span className="FColStats_claim_content_danger">
					<Trans i18nKey="fcol_cabinet_3" />
				</span>
			</div>
		</>
	)
}
