import Page from 'src/components/Page'
import { useTranslation } from 'react-i18next'
import Layout from '../components/Layout/Layout'
import FColTitle from '../components/Title'

export default function FColSecurityPage() {
	const { t } = useTranslation()

	return (
		<Page title={t('fcol_security_page_title')}>
			<Layout>
				<div className="FColSecurityPage">
					<FColTitle>{t('fcol_security_page_title')}</FColTitle>
				</div>
			</Layout>
		</Page>
	)
}
