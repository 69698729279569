import React, { useState, useEffect } from 'react'
import FColBinaryStructureItem from './item'
import { Address } from 'wagmi'

import './style.scss'

export const itemConfig = {
	width: 200,
	height: 220,
	margin: 30,
	canvasMargin: 150,
}

interface Props {
	width: number
	height: number
	address: Address
	setAddress: (address: Address) => void
	setHeight: (height: number) => void
}

export default function FColBinarStructureCanvas(props: Props) {
	const [width, setWidth] = useState(props.width)
	const [height, setHeight] = useState(props.height)
	const [leftChildNum, setLeftChildNum] = useState<number>(0)
	const [rightChildNum, setRightChildNum] = useState<number>(0)
	const [lastLvl, setLastLvl] = useState(4)
	const [parentX, setParentX] = useState<number>(0)
	const lvl = 0
	const defaultLvl = 4

	useEffect(() => {
		setWidth(
			(itemConfig.width + itemConfig.margin) *
				Math.max(leftChildNum * 2 + 1, rightChildNum * 2 + 1, defaultLvl * 2)
		)
		setHeight(
			(itemConfig.height + itemConfig.margin) * Math.max(lastLvl + 1, 2) +
				itemConfig.canvasMargin * 2
		)
		setParentX(
			(width -
				(itemConfig.width + itemConfig.margin) *
					(leftChildNum + rightChildNum)) /
				2
		)
	}, [props.width, lastLvl, leftChildNum, rightChildNum, width])

	useEffect(() => {
		props.setHeight(width / height)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [height])

	const handlerChildCount = (index: number, childNum: number, lvl: number) => {
		if (!index) setLeftChildNum(childNum)
		if (index) setRightChildNum(childNum)
		setLastLvl(lvl)
	}

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="svg_root"
			x={0}
			y={0}
			width={props.width}
			height={(height * props.width) / width}
			viewBox={'0, 0, ' + width + ', ' + height}
			fill={'transparent'}
		>
			<FColBinaryStructureItem
				lvl={lvl}
				index={0}
				address={props.address}
				setAddress={props.setAddress}
				canvasWidth={width}
				parentX={parentX}
				parentDX={0}
				leftChildNum={leftChildNum}
				rightChildNum={rightChildNum}
				setChildCount={handlerChildCount}
			/>
		</svg>
	)
}
