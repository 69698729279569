import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useModal } from 'react-simple-modal-provider'
import { toast } from 'react-toastify'
import { HandySvg } from 'handy-svg'
import { ethers } from 'ethers'
import { useDispatch } from 'react-redux'
import {
	isShowMobileSidebar,
	toggleMobileSidebar,
} from 'src/store/reducers/mobileSidebar'
import {
	useAccount,
	useContractRead,
	useContractWrite,
	usePrepareContractWrite,
	useWaitForTransaction,
} from 'wagmi'
import Cookies from 'js-cookie'

import { useContract } from 'src/hooks/useContract'
import copyTextToClipboard from 'src/utils/copyTextToClipboard'
import getShortWallet from 'src/utils/getShortWallet'
import { FCOL_ADDRESS } from 'src/constants'
import GrabCoinClubAbi from 'src/abis/GrabCoinClubAbi.json'

import Submenu from './Submenu'

import IconCopy from 'src/icons/copy.svg'
import IconNav1 from 'src/icons/nav1.svg'
import IconNav2 from 'src/icons/nav2.svg'
import IconNav3 from 'src/icons/nav3.svg'
import IconNav6 from 'src/icons/nav6.svg'
import './Sidebar.scss'

export default function Sidebar() {
	const { t } = useTranslation()
	const inputRefLink = useRef<HTMLInputElement>(null)
	const { address } = useAccount()
	const {
		nftCollection,
		isApprovedForAll,
		refetchIsApprovedForAll,
		referrer,
		stakedTokenId,
	} = useContract()

	const dispatch = useDispatch()

	const { data: gen } = useContractRead({
		address: nftCollection,
		abi: GrabCoinClubAbi,
		functionName: 'seriesOf',
		args: [stakedTokenId],
		enabled: stakedTokenId >= 0,
	})

	const { config: approveConfig } = usePrepareContractWrite({
		address: nftCollection,
		abi: GrabCoinClubAbi,
		functionName: 'setApprovalForAll',
		args: [FCOL_ADDRESS, true],
		enabled: isApprovedForAll === false,
	})
	const { data: approveData, write: approveWrite } =
		useContractWrite(approveConfig)
	const { isLoading: approveIsLoading } = useWaitForTransaction({
		hash: approveData?.hash,
		onSuccess: () => {
			refetchIsApprovedForAll?.()
		},
	})

	const handleSelectRefLink = () => {
		if (inputRefLink.current) {
			inputRefLink.current.select()
		}
	}

	const refLink = window.location.origin + '/' + address
	const copy = () =>
		copyTextToClipboard(refLink).then((result) => {
			if (result) {
				toast.success(t('notify_refLink_copied'))
			} else {
				toast.error(t('notify_error'))
			}
		})

	const { open: openFColModalInviter, close: closeFColModalInviter } =
		useModal('FColModalInviter')

	const sidebarBtn = useSelector(isShowMobileSidebar)

	const ManualLink = () => {
		const lang = Cookies.get('locale')
		window.open('/doc/OpenSmart_' + (lang === undefined ? 'en' : lang) + '.pdf')
		closeSidebar()
	}

	const closeSidebar = () => {
		if (sidebarBtn) dispatch(toggleMobileSidebar())
	}

	return (
		<aside className={'Sidebar' + (sidebarBtn ? ' Sidebar--active' : '')}>
			<div className="SidebarInfo">
				<div className="SidebarInfo-row">
					<b>{t('fcol_sidebar_nft_level')}</b>
					<span>
						{gen ?? -1 >= 0 ? (
							`Gen ${Number(gen) + 1}`
						) : isApprovedForAll === false ? (
							<button
								className="btn btn--matic btn--sm"
								disabled={approveIsLoading || !approveWrite}
								onClick={() => approveWrite?.()}
							>
								{t('fcol_nft_approve_btn')}
							</button>
						) : (
							'---'
						)}
					</span>
				</div>
				<div className="SidebarInfo-row">
					<b>{t('fcol_sidebar_wallet')}</b>
					<span>{getShortWallet(address)}</span>
				</div>
				<div className="SidebarInfo-row">
					<b>{t('fcol_sidebar_inviter')}</b>
					<span>
						{referrer !== ethers.constants.AddressZero ? (
							getShortWallet(referrer)
						) : (
							<button
								className="btn btn--matic btn--sm"
								onClick={() =>
									openFColModalInviter({
										close: closeFColModalInviter,
									})
								}
							>
								{t('btn_add')}
							</button>
						)}
					</span>
				</div>
				<div className="SidebarInfo-refLink">
					<strong>{t('fcol_sidebar_refLink')}</strong>
					<div className="SidebarInfo-refLink_input">
						<input
							type="text"
							value={refLink}
							readOnly
							ref={inputRefLink}
							onClick={handleSelectRefLink}
						/>
						<button type="button" onClick={copy}>
							<HandySvg src={IconCopy} />
						</button>
					</div>
				</div>
			</div>

			<nav className="Sidebar-nav">
				<Link
					to="/account/fcol"
					className={
						window.location.pathname === '/account/fcol' ? 'active' : ''
					}
					onClick={() => closeSidebar()}
				>
					<HandySvg src={IconNav1} />
					<span>{t('fcol_sidebar_nav_home')}</span>
				</Link>
				<Submenu
					navText={t('fcol_sidebar_nav_NFT')}
					navIcon={IconNav6}
					navLink={'account/fcol/NFT'}
				>
					<nav className="Sidebar-subitem">
						<Link
							to="/account/fcol/NFT/mint"
							className={
								window.location.pathname === '/account/fcol/NFT/mint'
									? 'active'
									: ''
							}
							onClick={() => closeSidebar()}
						>
							<span>{t('fcol_sidebar_nav_NFT_mint')}</span>
						</Link>
						<Link
							to="/account/fcol/NFT/my-mint"
							className={
								window.location.pathname === '/account/fcol/NFT/my-mint'
									? 'active'
									: ''
							}
							onClick={() => closeSidebar()}
						>
							<span>{t('fcol_sidebar_nav_NFT_my')}</span>
						</Link>
					</nav>
				</Submenu>

				<Link
					to="/account/fcol/binar"
					className={
						window.location.pathname === '/account/fcol/binar' ? 'active' : ''
					}
					onClick={() => closeSidebar()}
				>
					<HandySvg src={IconNav2} />
					<span>{t('fcol_sidebar_nav_binar')}</span>
				</Link>
				<Link
					to="/account/fcol/line"
					className={
						window.location.pathname === '/account/fcol/line' ? 'active' : ''
					}
					onClick={() => closeSidebar()}
				>
					<HandySvg src={IconNav3} />
					<span>{t('fcol_sidebar_nav_line')}</span>
				</Link>
				<Link onClick={ManualLink} to="" rel="noreferrer">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="48"
						height="48"
						viewBox="0 0 48 48"
					>
						<g
							fill="none"
							stroke="#21E786"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="4"
						>
							<path d="M39 4H11a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM17 30h14m-14 6h7" />
							<path d="M17 12h14v10H17z" />
						</g>
					</svg>
					<span>{t('fcol_open_smart_manual')}</span>
				</Link>
				{/*<Link*/}
				{/*	to="/account/fcol/transactions"*/}
				{/*	className={*/}
				{/*		window.location.pathname === '/account/fcol/transactions'*/}
				{/*			? 'active'*/}
				{/*			: ''*/}
				{/*	}*/}
				{/*>*/}
				{/*	<HandySvg src={IconNav4} />*/}
				{/*	<span>{t('fcol_sidebar_nav_transactions')}</span>*/}
				{/*</Link>*/}
				{/*<Link*/}
				{/*	to="/account/fcol/security"*/}
				{/*	className={*/}
				{/*		window.location.pathname === '/account/fcol/security'*/}
				{/*			? 'active'*/}
				{/*			: ''*/}
				{/*	}*/}
				{/*>*/}
				{/*	<HandySvg src={IconNav5} />*/}
				{/*	<span>{t('fcol_sidebar_nav_security')}</span>*/}
				{/*</Link>*/}
			</nav>
		</aside>
	)
}
