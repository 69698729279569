export default async function copyTextToClipboard(text) {
	if (!navigator.clipboard) {
		const fakeElement = document.createElement('textarea')
		fakeElement.style.fontSize = '12pt'
		fakeElement.style.border = '0'
		fakeElement.style.padding = '0'
		fakeElement.style.margin = '0'
		fakeElement.style.position = 'absolute'
		fakeElement.style.left = '-9999px'
		let yPosition = window.pageYOffset || document.documentElement.scrollTop
		fakeElement.style.top = `${yPosition}px`

		fakeElement.setAttribute('readonly', '')
		fakeElement.value = text

		document.body.appendChild(fakeElement)
		fakeElement.focus()
		fakeElement.select()

		let result
		try {
			result = document.execCommand('copy')
		} catch (err) {
			result = false
		}
		document.body.removeChild(fakeElement)
		return result
	} else {
		return navigator.clipboard.writeText(text).then(
			function () {
				return true
			},
			function (err) {
				return false
			}
		)
	}
}
